import React, { useState, useRef, useEffect } from "react";
import { AiOutlineExpand } from "react-icons/ai";
import {
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdShare,
} from "react-icons/md";
import { IoHomeOutline, IoClose } from "react-icons/io5";
import { FiMinimize } from "react-icons/fi";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "./Redux/Action/Action";
import Modal from "react-bootstrap/Modal";
import ksmacc from "./Assets/Group 86.png";
import kpost from "./Assets/Group 96.png";
import wp from "./Assets/Group 88.png";
import fb from "./Assets/Group 90.png";
import twit from "./Assets/Group 92.png";
import email from "./Assets/Group 94.png";

const IframeView = ({
  index,
  expandedIndex,
  isMobile,
  Browsers,
  browser,
  handleExpand,
  handleClose,
  handleBookmark,
  handlebooks,
  isClicked,
  key,
}) => {
  const newref = useRef(null);

  console.log(Browsers, "kjkj");

  console.log(Browsers[0]?.view, "hjjjj");

  console.log(index, "indexes");
  console.log(Browsers[1], "browsers1");

  // const newBookmark = {
  //   id: Browsers[index]?.id,
  //   name: Browsers[index]?.name,
  //   view: Browsers[index]?.view,
  // };

  const [shareiconmodal, SetShareIconModal] = useState(false);

  const share = [
    { image: ksmacc, name: "ksmacc" },
    { image: kpost, name: "Kpost", link: "https://account.kpostindia.com/" },
    { image: wp, name: "Whatsapp", link: "https://web.whatsapp.com/" },
    { image: fb, name: "Facebook", link: "https://www.facebook.com/" },
    { image: twit, name: "Twitter", link: "https://x.com/" },
    { image: email, name: "Email", link: "https://mail.google.com" },
  ];

  const openApplication = (link) => {
    window.open(`${link}`, "_blank");
  };

  const [copyurl, SetCopyUrl] = useState("");
  const [message,setMessage] = useState("");
  const [messagecolor,setMessageColor] = useState("")

  const handleCloseModal = () => {
    SetShareIconModal(false);
    setMessage(false);
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyurl);
      setMessage('URL copied to clipboard successfully!');
      setMessageColor("text-success fs-6")
      console.log('URL copied to clipboard:', copyurl);
    } catch (err) {
      setMessage('Failed to copy URL.');
      setMessageColor("text-danger")
      console.error('Failed to copy URL:', err);
    }
  };

  const handleopenshare = () =>{
    SetShareIconModal(true);
    SetCopyUrl(browser.view)
  }

  useEffect(() => {
    console.log(Browsers, " Browsers[index]")
  }, [Browsers])

  return (
    <div
      key={index}
      className={
        isMobile && expandedIndex === null && index === 0
          ? "col-12"
          : isMobile && expandedIndex === null && index === 1
            ? "d-none"
            : expandedIndex === null
              ? `col-${Browsers.length > 1 ? 6 : 8} p-1`
              : expandedIndex === index
                ? "col-12"
                : `${Browsers.length > 1 ? 6 : "col-8"} p-1 d-none`
      }
    >
      <div
        className="w-100 shadow-sm fullViewHeight"
        style={{
          height: window.innerHeight - 125 + "px",
        }}
      >
        <div className="d-flex flex-row align-items-center justify-content-between p-2 border-bottom headCollio">
          <div className="d-flex align-items-center gap-2">
            <MdChevronLeft
              className="pointer"
              size={24}
              onClick={() => {
                // if (newref.current) {
                // var iframe = document.getElementById(browser.id);
                // var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                // var iframeUrl = iframeDocument.URL;

                // console.log(iframeUrl,"document url");

                // console.log(newref.current.src,"current src")
                window.history.back();
                // newref.current.contentWindow.history.back();
                // newref.current.contentWindow.postMessage('goback', '*');
                // document.getElementById(browser.id).contentWindow.history.back();
                // iframe.contentWindow.history.back();
                // }
              }}
            />
            <IoHomeOutline
              className="pointer"
              size={18}
              onClick={() => {
                if (newref.current) {
                  newref.current.src = newref.current.src;
                }
              }}
            />
            <MdChevronRight
              className="pointer"
              size={24}
              onClick={() => {
                // if (newref.current) {
                window.history.forward();
                // }
              }}
            />
          </div>
          <div className="text-center">
            <h5>{browser.name}</h5>
          </div>
          <div className="d-flex align-items-center gap-3">
            <MdShare
              className="pointer"
              onClick={handleopenshare}
            />
            {browser?.bookmarked ? (
              <BsBookmarkFill
                key={key}
                onClick={() => handleBookmark(index,browser)}
                className="pointer"
                style={{ color: "#B24BD6" }}
              />
            ) : (
              <BsBookmark
                size={15}
                className="pointer"
                onClick={() => handleBookmark(index,browser)}
              />
            )}

            {!isMobile && (
              <span className="pointer" onClick={() => handleExpand(index)}>
                {expandedIndex === index ? (
                  <FiMinimize size={"1em"} />
                ) : (
                  <AiOutlineExpand size={"1em"} />
                )}
              </span>
            )}
            <IoClose
              size={"1.3em"}
              onClick={() => handleClose(index)}
              className="pointer"
            />
          </div>
        </div>

        <iframe
          style={{
            height: window.innerHeight - 170 + "px",
          }}
          src={browser.view}
          loading="lazy"
          frameborder="0"
          allowtransparency="true"
          title={`view-${index}`}
          className="w-100 iframe_height"
          id={browser.id}
          ref={newref}
        />
      </div>
      <Modal show={shareiconmodal} centered>
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <Modal.Title>Share</Modal.Title>
          <MdClose
            size={20}
            onClick={handleCloseModal}
            className="pointer"
          />
        </Modal.Header>
        <Modal.Body className="modal-body-share">
          <div className="d-flex gap-4 align-items-center ">
            {share.map((item, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center flex-column pointer"
                onClick={() => openApplication(item.link)}
              >
                <img src={item.image} alt="" width={"40px"} />
                <span className="font_size">{item.name}</span>
              </div>
            ))}
          </div>
          <div className="d-flex gap-3 py-3">
            <input type="text" value={copyurl} onChange={(e)=>SetCopyUrl(e.target.value)} className="w-100 input_border" />
            <button className="clearall_button" onClick={handleCopy}>Copy</button>
          </div>
          <span className={messagecolor}>{message}</span>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IframeView;
