import React, { useState, useRef, useEffect, Fragment } from "react";
import {
  FaMicrophone,
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
  FaRegStopCircle,
} from "react-icons/fa";
import { AiOutlineRightSquare, AiOutlineSearch } from "react-icons/ai";
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
  IoClose,
} from "react-icons/io5";
import {
  MdArrowLeft,
  MdArrowOutward,
  MdDelete,
  MdOutlineTranslate,
} from "react-icons/md";
import ai from "../Assets/AI_block.png";
import diary from "../Assets/dictionary.ico";
import booksearch from "../Assets/Vector.png";
import { Col, Row } from "react-bootstrap";
import { AiOutlineExpand } from "react-icons/ai";
import searchks from "../Assets/Group 1000006739.png";
import gif1 from "../Assets/giphy.webp";
import gif2 from "../Assets/indian_saree_saree.gif";
import gif3 from "../Assets/giphy1.webp";
import gif4 from "../Assets/Escentual_Best_Black_Friday_Perfume_Deals_2021.gif";
import gif5 from "../Assets/ezgif.com_gif_maker.gif";
import giphystraw from "../Assets/giphystraw.webp";
import bing from "../Assets/Ellipse 213.png";
import IQ from "../Assets/iq.png";
import swiss from "../Assets/swiss.png";
import navar from "../Assets/navar.png";
import way from "../Assets/way.png";
import lastuio from "../Assets/lastuio.png";
import yep from "../Assets/yes.png";
import open from "../Assets/open.png";
import seo from "../Assets/Ellipse 213 (1).png";
import news from "../Assets/News_black.png";
import MXNet from "../Assets/1.png";
import AVIAs from "../Assets/2.png";
import Adobe from "../Assets/3.png";
import BOXs from "../Assets/4.png";
import Claude from "../Assets/5.png";
import Copysmith from "../Assets/6.png";
import brit from "../Assets/brit.png";
import SHAB from "../Assets/shabdkosh-64t.png";
import wiki from "../Assets/wiki.png";
import kid from "../Assets/kid.png";
import scholar from "../Assets/scholar.png";
import encyclo from "../Assets/encyclo.png";
import newww from "../Assets/new.png";
import google from "../Assets/google.png";
import deepl from "../Assets/deepl.png";
import micro from "../Assets/micro.png";
import itrans from "../Assets/itrans.png";
import revQ from "../Assets/revQ.png";
import yandex from "../Assets/yandex.png";
import merri from "../Assets/merri.png";
import oxford from "../Assets/oxford.png";
import cambridge from "../Assets/cambridge.png";
import dictcom from "../Assets/dictcom.png";
import wordref from "../Assets/wordref.png";
import youdic from "../Assets/youdic.png";
import dainik from "../Assets/dainik.png";
import daily from "../Assets/daily.png";
import hindu from "../Assets/hindu.png";
import times from "../Assets/times.png";
import hindustan from "../Assets/hindustan.png";
import indian from "../Assets/indian.png";
import aiwhite from "../Assets/AI_white2.png";
import bookwhite from "../Assets/ENCYCLOPEDIA_white3.png";
import diarywhite from "../Assets/Dictionary_white2.png";
import newswhite from "../Assets/NEWS.png";
import lotus from "../Assets/image-removebg 1.png";
import first from "../Assets/Rectangle 34624371.png";
import sec from "../Assets/Rectangle 34624371 (1).png";
import thir from "../Assets/Rectangle 34624371 (2).png";
import fou from "../Assets/Rectangle 34624373.png";
import fiv from "../Assets/Rectangle 34624373 (1).png";
import six from "../Assets/Rectangle 34624371 (3).png";
import Header from "../Header/Header";
import IframeView from "../IframeView";
import { useNavigate } from "react-router-dom";
import add1 from "../Assets/Rectangle 34624486.png";
import add2 from "../Assets/Rectangle 34624486 (1).png";
import add3 from "../Assets/Rectangle 34624487.png";
import add4 from "../Assets/Rectangle 34624488.png";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import mark from "../Assets/icomoon-free_books.png";
import { BsBookmarkFill } from "react-icons/bs";
import back1 from "../Assets/back1.jpg";
import back2 from "../Assets/back2.jpg";
import back3 from "../Assets/back3.jpg";
import his from "../Assets/img/Group 1000006969 (1).png";
import books from "../Assets/img/Group 1000006969.png"
import prev from "../Assets/img/prevv.png";
import next from "../Assets/img/nexxt.png";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { useTranslation } from "react-i18next";
import Modalc from "../Common/Modalc";
import { addBrowsersData, addItem, removeItem } from "../Redux/Action/Action";
import { BookmarkRemove, BookmarkPost, GetUrl, PostUrl, GetBookmartData, HistoryRemove } from "../Services/SearchApi";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { MdClose } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import Button from "../Common/Button1";


const Search = ({ showbookmarks, setShowBookMarks, }) => {
  const { t, i18n } = useTranslation();
  const AuthUser = localStorage.getItem("Authuser");
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLang(language);
  };

  const CardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (CardRef.current && !CardRef.current.contains(event.target)) {
        setShowBookMarks({ showbookmarks, bookmark: false });
        setShowBookMarks({ showbookmarks, history: false });
        setShowDropdown(false);
        setShowBookSearchCard(false);
        setAiSearch(false);
        setTranslatesearch(false);
        setDictionarysearch(false);
        setshowNewsCard(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const BrowsersData = useSelector((state) => state.BrowsersData);


  useEffect(() => {
    console.log(BrowsersData, "bookmarkednewwww")
  }, [BrowsersData]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();
  const [selectedLang, setSelectedLang] = useState(null);

  const [homeView, setHomeView] = React.useState(true);
  const [homeIconView, setHomeIconView] = React.useState(false);
  const [searchInputField, setSearchInputField] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isInputVisible, setInputVisible] = useState(false);

  const [fullView, setFullView] = React.useState(false);
  const [Browsers, setBrowsers] = React.useState([]);
  const [showBookSearchCard, setShowBookSearchCard] = useState(false);
  const [aisearch, setAiSearch] = useState(false);
  const [translatesearch, setTranslatesearch] = useState(false);
  const [dictionarysearch, setDictionarysearch] = useState(false);
  const [showNewsCard, setshowNewsCard] = useState(false);

  const [isRecording, setisRecording] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [storebookmark, setStoreBookmark] = useState([]);
  const [historythree, setHistoryThree] = useState(false);

  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const microphone = new SpeechRecognition();

  microphone.continuous = true;
  microphone.interimResults = true;
  microphone.lang = "en-IN";

  const startRecordController = () => {
    // alert(isRecording)
    console.log(isRecording, "Micro phone status updation");
    if (String(isRecording) === "true") {
      microphone.start();

      microphone.onresult = (event) => {
        const recordingResult = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        console.log(recordingResult, "Micro phone status updation");
        setSearchInputField(recordingResult);
        microphone.onerror = (event) => {
          console.log(event.error, "Micro phone status updation");
        };
      };
      microphone.onerror = (event) => {
        console.log(event.error, "Micro phone status updation");
      };
    } else {
      microphone.stop();
      //   microphone.start();
      microphone.onend = () => {
        console.log(
          "Stopped microphone on Click",
          "Micro phone status updation"
        );
        // setTimeout(() => {
        //   microphone.onresult = null;
        // microphone.onerror = null;
        // }, 0);
      };
    }
  };

  useEffect(() => {
    startRecordController();
  }, [isRecording]);

  const handleFirstSetup = () => {
    setHomeIconView(true);
    setHomeView(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchInputField.trim() !== "") {
        handleFirstSetup();
      } else {
        alert("Please enter a search term.");
      }
      setisRecording(false);
    }
  };

  const handleDropdownItemClick = (engine) => {
    // console.log(index,"index")
    console.log(engine, "engine")

    if (searchInputField.trim() !== "") {
      const searchUrl = (engine.searchUrl || engine.url).replace(
        /{searchTermSSS}|{searchInputField}/,
        encodeURIComponent(searchInputField)
      );
      console.log(searchUrl, "searchUrl");
      handleView(searchUrl, engine?.name, engine?.id);
      setShowDropdown(false);
      setShowBookSearchCard(false);
      setAiSearch(false);
      setTranslatesearch(false);
      setDictionarysearch(false);
      setshowNewsCard(false);
      handlePostUrl(searchUrl)
    } else {
      alert("Please enter a search term.");
    }
  };

  const slideImage = [
    { img: first, btn: "Search Engine" },
    { img: sec, btn: "AI Tool" },
    { img: thir, btn: "Encyclopedia" },
    { img: fou, btn: "Translator" },
    { img: fiv, btn: "Dictionary" },
    { img: six, btn: "News" },
  ];

  const [visibleItems, setVisibleItems] = useState(3);

  const handleNextClick = () => {
    setVisibleItems(slideImage.length);
  };

  const handlePreviousClick = () => {
    setVisibleItems(3);
  };


  const handleSearchClick = () => {
    setShowDropdown(!showDropdown);
    setShowBookSearchCard(false);
    setAiSearch(false);
    setTranslatesearch(false);
    setDictionarysearch(false);
    setInputVisible(false);
    setshowNewsCard(false);
    setSearchTerm("");
  };

  const handleBook = () => {
    setShowBookSearchCard(!showBookSearchCard);
    setShowDropdown(false);
    setAiSearch(false);
    setTranslatesearch(false);
    setDictionarysearch(false);
    setshowNewsCard(false);
    setSearchTerm("");
  };

  const handleAi = () => {
    setAiSearch(!aisearch);
    setShowDropdown(false);
    setShowBookSearchCard(false);
    setTranslatesearch(false);
    setDictionarysearch(false);
    setshowNewsCard(false);
    setSearchTerm("");
  };

  const handleTranslate = () => {
    setTranslatesearch(!translatesearch);
    setShowDropdown(false);
    setShowBookSearchCard(false);
    setAiSearch(false);
    setDictionarysearch(false);
    setshowNewsCard(false);
    setSearchTerm("");
  };

  const handleDictionary = () => {
    setDictionarysearch(!dictionarysearch);
    setShowDropdown(false);
    setShowBookSearchCard(false);
    setAiSearch(false);
    setTranslatesearch(false);
    setshowNewsCard(false);
    setSearchTerm("");
  };

  const handleNews = () => {
    setshowNewsCard(!showNewsCard);
    setShowBookSearchCard(false);
    setDictionarysearch(false);
    setShowDropdown(false);
    setShowBookSearchCard(false);
    setAiSearch(false);
    setTranslatesearch(false);
    setSearchTerm("");
  };

  const handleView = (view, name, id) => {
    setBrowsers([{ view, name, id }, ...Browsers]);
    setFullView(true);
    // const newBrowserData = {
    //   id:id,
    //   name:name,
    //   view: view,
    // };
    // console.log(newBrowserData,"newbrowserdata")
    // dispatch(addBrowsersData(newBrowserData));
  };

  const handleClose = (index) => {
    setBrowsers((brows) => brows.filter((s, i) => i !== index));
  };

  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();

  const handlebookmarks = (index, browser) => {
    console.log("handlebooks function called", index, browser);
    // debugger
    // const updatedBrowsers = [...BrowsersData];

    const updatedBrowsers = [...Browsers];

    // const isBookmarked = updatedBrowsers[index].bookmarked;
    // console.log(updatedBrowsers, "updatedBrowserslen")
    // console.log(updatedBrowsers[index], "updatedBrowsers[index]")

    // const name = updatedBrowsers[index]?.name;
    // const view = updatedBrowsers[index]?.view;

    const isBookmarked = browser?.bookmarked;
    const name = browser?.name;
    const view = browser?.view;

    console.log(name, "name")
    console.log(view, "view")

    if (!isBookmarked) {
      console.log("ADD Bookmark")
      handleBookmarkPost(name, view, index, browser);
    } else {
      console.log("remove bookmark")
      // handleBookmarkRemove(updatedBrowsers[index]?.bookmarkid, index)
      handleBookmarkRemove(browser?.bookmarkid, index)

    }


    // const newBookmark = {
    //   id: Browsers[index]?.id,
    //   name: Browsers[index]?.name,
    //   view: Browsers[index]?.view,
    // };
    // dispatch(addItem(newBookmark));

    // console.log(handlebookmarks,"bookmark")

    // if (isBookmarked) {  
    //   dispatch(removeItem(Browsers[index]?.id));
    // } else {
    //   const newBookmark = {
    //     id: Browsers[index]?.id,
    //     name: Browsers[index]?.name,
    //     view: Browsers[index]?.view,
    //   };
    //   dispatch(addItem(newBookmark));
    // }

    // setIsClicked(!isClicked);
  };


  const Languages = [
    { lang: "English", langCode: "en" },
    { lang: "हिंदी", langCode: "hi" },
    { lang: "বাংলা", langCode: "bn" },
    // { lang: "اردو", langCode: "ur" },
    { lang: "ਪੰਜਾਬੀ", langCode: "pa" },
    { lang: "मराठी", langCode: "mr" },
    { lang: "తెలుగు", langCode: "te" },
    { lang: "தமிழ்", langCode: "ta" },
    { lang: "ಕನ್ನಡ", langCode: "kn" },
    { lang: "ગુજરાતી", langCode: "gu" },
    { lang: "മലയാളം", langCode: "ml" },
    { lang: "ଓଡ଼ିଆ", langCode: "or" },
  ];

  const searchEngines = [
    {
      id: "SearchUrl1",
      name: "Bing",
      logo: bing,
      url: "https://www.bing.com/search?q={searchInputField}",
    },
    {
      id: "SearchUrl2",
      name: "BrowSEO",
      logo: seo,
      url: "https://www.browseo.net/search?q={searchInputField}",
    },
    {
      id: "SearchUrl3",
      name: "Yep.com",
      logo: yep,
      url: "https://yep.com/web?q={searchInputField}",
    },
    // {
    //   id: "SearchUrl4",
    //   name: "Openverse",
    //   logo: open,
    //   url: "https://openverse.org/search/?q={searchInputField}",
    // },
    {
      id: "SearchUrl5",
      name: "Swisscows",
      logo: swiss,
      url: "https://www.swisscows.com/web?query={searchInputField}",
    },
    // {
    //   id: "SearchUrl6",
    //   name: "SlideShare",
    //   logo: navar,
    //   url: "https://www.slideshare.net/search/slideshow?q={searchInputField}",
    // },
    {
      id: "SearchUrl7",
      name: "Wayback Machine",
      logo: way,
      url: "https://web.archive.org/web/*/{searchInputField}",
    },
    {
      id: "SearchUrl8",
      name: "Sogou",
      logo: lastuio,
      url: "https://sogou.com/web?query={searchInputField}",
    },
    // {id: "SearchUrl9", name: 'Naver', logo: bing, url: 'https://m.search.naver.com/search.naver?query={searchInputField}' },
  ];
  const filteredEngines = searchEngines.filter((engine) =>
    engine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const AiEngines = [
    {
      id: "SearchUrl10",
      name: "You",
      logo: MXNet,
      url: "https://you.com/search?q={searchInputField}",
    },
    // {
    //   id: "SearchUrl11",
    //   name: "AVIA",
    //   logo: AVIAs,
    //   url: "https://www.perplexity.ai/search/{searchInputField}",
    // },
    {
      id: "SearchUrl12",
      name: "Adobe Firefly",
      logo: Adobe,
      url: "https://www.adobe.com/",
    },
    // { id: "SearchUrl13", name: "BOX AI", logo: BOXs, url: "https://box.ai/" },
    // {
    //   id: "SearchUrl14",
    //   name: "Claude",
    //   logo: Claude,
    //   url: "https://claude.ai/",
    // },
    {
      id: "SearchUrl15",
      name: "Copysmith",
      logo: Copysmith,
      url: "https://copysmith.ai/}",
    },
  ];
  const filteredAiEngines = AiEngines.filter((engine) =>
    engine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const translateEngines = [
    {
      id: "SearchUrl16",
      name: "Microsoft Translator",
      logo: micro,
      searchUrl: "https://www.bing.com/translator?text={searchTermSSS}",
    },
    // {
    //   id: "SearchUrl17",
    //   name: "ReversQ translation",
    //   logo: revQ,
    //   searchUrl:
    //     "https://www.reverso.net/text-translation#sl=eng&tl=fra&text={searchTermSSS}",
    // },
     //{ name: 'Google Translate', logo: google, searchUrl: 'https://translate.google.co.in/?sl=auto&tl=en&text={searchTermSSS}&op=translate' },
     //{ name: 'DeepL translator', logo: deepl, searchUrl: 'https://www.deepl.com/translator#en/en/{searchTermSSS}' },
     //{ name: 'iTranslate', logo: itrans, searchUrl: 'https://itranslate.com/?text={searchTermSSS}' },
     //{ name: 'Yandex.Translator', logo: yandex, searchUrl: 'https://translate.yandex.com/?text={searchTermSSS}' }
  ];
  const filteredTranslateEngines = translateEngines.filter((engine) =>
    engine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [filteredwikiEngines, setFilteredWikiEngines] = useState([]);

  const wikiEngines = React.useMemo(
    () => [
      {
        id: "SearchUrl18",
        name: "Britannica",
        logo: brit,
        searchUrl: "https://www.britannica.com/search?query={searchTermSSS}",
      },
      {
        id: "SearchUrl19",
        name: "Wikipedia",
        logo: wiki,
        searchUrl:
          "https://en.wikipedia.org/w/index.php?search={searchTermSSS}",
      },
      {
        id: "SearchUrl20",
        name: "Britannica Kids",
        logo: kid,
        searchUrl: "https://kids.britannica.com/search?query={searchTermSSS}",
      },
      {
        id: "SearchUrl21",
        name: "Scholarpedia",
        logo: scholar,
        searchUrl:
          "http://www.scholarpedia.org/w/index.php?search={searchTermSSS}",
      },
      {
        id: "SearchUrl22",
        name: "IQ.wiki",
        logo: IQ,
        searchUrl: "https://iq.wiki/wiki/{searchTermSSS}",
      },
      {
        id: "SearchUrl23",
        name: "New World Encyclopedia",
        logo: newww,
        searchUrl:
          "https://www.newworldencyclopedia.org/entry/Special:Search?search={searchTermSSS}",
      },
    ],
    []
  );

  const dicEngines = [
    {
      id: "SearchUrl24",
      name: "Merriam Webster",
      logo: merri,
      searchUrl: "https://www.merriam-webster.com/dictionary/{searchTermSSS}",
    },
    {
      id: "SearchUrl25",
      name: "Dictionary.com",
      logo: dictcom,
      searchUrl: "https://www.dictionary.com/browse/{searchTermSSS}",
    },
    {
      id: "SearchUrl26",
      name: "Word Reference",
      logo: wordref,
      searchUrl: "https://www.wordreference.com/definition/{searchTermSSS}",
    },
    {
      id: "SearchUrl27",
      name: "Your Dictionary",
      logo: youdic,
      searchUrl: "https://www.yourdictionary.com/{searchTermSSS}",
    },
    {
      id: "SearchUrl28",
      name: "Shabdkosh Dictionary",
      logo: SHAB,
      searchUrl:
        "https://www.shabdkosh.com/search-dictionary?lc=hi&sl=en&tl=hi&e={searchTermSSS}",
    },
  ];
  const filteredDicEngines = dicEngines.filter((engine) =>
    engine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const NewsEngines = [
    {
      id: "SearchUrl29",
      name: "Dainik Jagran (Hindi)",
      logo: dainik,
      searchUrl: "https://www.jagran.com/search/{searchTermSSS}",
    },
    {
      id: "SearchUrl30",
      name: "Daily Times India",
      logo: daily,
      searchUrl: "https://www.dailytimesindia.com/search?q={searchTermSSS}",
    },
    // { name: 'The Hindu', logo: hindu, searchUrl: 'https://www.thehindu.com/search/#gsc.tab=0&gsc.q={searchTermSSS}' },
    // { name: 'The Times of India', logo: times, searchUrl: 'https://timesofindia.indiatimes.com/topic/{searchTermSSS}' },
    // { name: 'Hindustan Times', logo: hindustan, searchUrl: 'https://www.hindustantimes.com/search?q={searchTermSSS}' },
    // { name: 'The Indian Express', logo: indian, searchUrl: 'https://indianexpress.com/?s={searchTermSSS}' }
  ];
  const filterednewsEngines = NewsEngines.filter((engine) =>
    engine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  React.useEffect(() => {
    const filteredEngineswiki = wikiEngines.filter((engine) =>
      engine.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredWikiEngines(filteredEngineswiki);
  }, [searchTerm, wikiEngines]);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const handleExpand = (index) => {
    // setExpandedIndex(expandedIndex === index + 1 ? 0 : index + 1);
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const carosel = [
    {
      image: add1,
      heading:
        "Exam Centers Must Have CCTV surveillance In Every Room: Yogi Adityanath ",
      middle: "India News | Asian News International | Sunday June 9, 2024",
      content:
        "Chief Minister Yogi Adityanath held an important meeting with the chairpersons of various selection commissions, in the presence of senior government officials, on Saturday.",
    },
    {
      image: add2,
      heading:
        "Womens's Participation Important. Swati Maliwals's Message To Voters",
      middle: "India News | Asian News International | Sunday May 25, 2024",
      content:
        "Chief Minister Yogi Adityanath held an important meeting with the chairpersons of various selection commissions, in the presence of senior government officials, on Saturday.",
    },
    {
      image: add3,
      heading:
        "Exam Centers Must Have CCTV surveillance In Every Room: Yogi Adityanath ",
      middle: "India News | Asian News International | Sunday June 9, 2024",
      content:
        "Chief Minister Yogi Adityanath held an important meeting with the chairpersons of various selection commissions, in the presence of senior government officials, on Saturday.",
    },
    {
      image: add4,
      heading:
        "Exam Centers Must Have CCTV surveillance In Every Room: Yogi Adityanath ",
      middle: "India News | Asian News International | Sunday June 9, 2024",
      content:
        "Chief Minister Yogi Adityanath held an important meeting with the chairpersons of various selection commissions, in the presence of senior government officials, on Saturday.",
    },
    {
      image: add1,
      heading:
        "Exam Centers Must Have CCTV surveillance In Every Room: Yogi Adityanath ",
      middle: "India News | Asian News International | Sunday June 9, 2024",
      content:
        "Chief Minister Yogi Adityanath held an important meeting with the chairpersons of various selection commissions, in the presence of senior government officials, on Saturday.",
    },
    {
      image: add2,
      heading:
        "Exam Centers Must Have CCTV surveillance In Every Room: Yogi Adityanath ",
      middle: "India News | Asian News International | Sunday June 9, 2024",
      content:
        "Chief Minister Yogi Adityanath held an important meeting with the chairpersons of various selection commissions, in the presence of senior government officials, on Saturday.",
    },
  ];

  // Advertisment
  const [startIndex, setStartIndex] = useState(0);

  const itemsPerPage = 3;

  const showNextData = () => {
    if (startIndex + itemsPerPage < carosel.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const showPreviousData = () => {
    if (startIndex - 1 >= 0) {
      setStartIndex(startIndex - 1);
    }
  };
  // Advertisment

  //nextindex and preindex

  const [initialset, Setinitialset] = useState(0);

  const itempage = 2;

  const handleIframeNext = () => {
    if (initialset + itempage < Browsers.length) {
      Setinitialset(initialset + 1)
    }
  }

  const handleIframePre = () => {
    if (initialset - 1 >= 0) {
      Setinitialset(initialset - 1)
    }
  }

  //nextindex and preindex

  // backgroundimage
  // const [showbookmarks, setShowBookMarks] = useState(false);

  const images = [{ image: back1 }, { image: back2 }, { image: back3 }];

  const [backgroundImage, setBackgroundImage] = useState(images[0].image);

  useEffect(() => {
    const updateBackgroundImage = () => {
      const currentDate = new Date();
      const index = Math.floor(currentDate.getTime() / 60000) % images.length;
      setBackgroundImage(images[index].image);
    };

    updateBackgroundImage();

    const intervalId = setInterval(updateBackgroundImage, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const searchBackgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  // backgroundimage

  //  History

  const [history, SetHistory] = useState([])

  const authUserObj = JSON.parse(AuthUser);

  // const firstName = authUserObj.firstName;
  // const lastName = authUserObj.lastName;
  const userid = authUserObj.kpostID
  console.log(userid, "asmn")

  const handlePostUrl = async (url) => {
    let bod = {
      userId: userid,
      url: url
    };
    console.log(bod, "body")
    try {
      let response = await PostUrl(bod);
      console.log(response, "kljsdfkljsjfkl");

      if (response.success === true) {
        console.log(response.message, "response");
        await GetAllHistory();
      }
    } catch (error) {
      console.error('Error in handlePostUrl:', error);
    }
  };

  const GetAllHistory = async () => {
    try {
      console.log(userid, "userdetails")
      let getresponse = await GetUrl(userid);
      SetHistory(getresponse?.data)
      console.log(getresponse, "history")
    } catch (error) {
      console.log("get all error", error)
    }
  }

  useEffect(() => {
    GetAllHistory();
  }, [userid]);

  const handleHistoryRemove = async (id) => {
    if (!Array.isArray(id)) {
      id = [id];
    }
    try {
      let bod = {
        userId: userid,
        historyIds: id
      }
      console.log(bod, "removehistory")
      let res = await HistoryRemove(bod);
      console.log(res, "reshistorydelete")
      if (res.success === true) {
        GetAllHistory();
      }
    } catch (error) {
      console.log(error, "delete not found")
    }
  }

  const handleClearAllHistory = async () => {
    const allids = history.map(item => item._id);
    await handleHistoryRemove(allids)
  }
  //History

  //bookmark
  const handleBookmarkPost = async (name, view, index, item) => {
    // debugger
    try {
      let body = {
        name: name,
        url: view,
        userId: userid
      }
      console.log(body, "bookmarkpost")
      const res = await BookmarkPost(body);
      console.log(res, "bookmarkadd")
      if (res.success === true) {
        console.log(res.message, "response");
        const bookmarkid = res?.data?._id;
        const updatedBrowsers = [...Browsers];
        const objindex = Browsers.findIndex((x) => x.view === item.view)
        if (objindex !== -1) {
          updatedBrowsers[objindex] = {
            ...updatedBrowsers[objindex],
            bookmarked: true,
            bookmarkid: bookmarkid
          }
          // dispatch(addBrowsersData(updatedBrowsers));
          setBrowsers(updatedBrowsers)
        }
        getbookmark();
      }
    } catch (error) {
      console.error('Error in handleBookmarkPost', error);
    }
  }

  const handleBookmarkRemove = async (id, index) => {
    // debugger
    try {
      let body = {
        bookmarkId: id,
        userId: userid
      }
      let response = await BookmarkRemove(body);
      console.log(response, "remove this")
      if (response.success === true) {

        const updatedBrowsers = [...Browsers];
        const reindex = Browsers.findIndex((x) => x.bookmarkid === id)

        updatedBrowsers[reindex] = {
          ...updatedBrowsers[reindex],
          bookmarked: false,
          bookmarkid: null
        }
        setBrowsers(updatedBrowsers)
        getbookmark();
      }
    } catch (error) {
      console.error('Error in handleBookmarkerror', error);
    }
  }

  const getbookmark = async () => {
    try {
      let body = {
        userId: userid
      }
      const response = await GetBookmartData(body);
      console.log(response, "responsedata")
      if (response.success === true) {
        setStoreBookmark(response?.data)
      }
    } catch (error) {
      console.error('Error in handlegetbookmark', error);
    }
  }

  useEffect(() => {
    getbookmark();
  }, [userid]);

  const handlehistoryiconclose = () => {
    setShowBookMarks({ showbookmarks, history: false });
  }

  const handlebookmarkiconclose = () => {
    setShowBookMarks({ showbookmarks, bookmark: false });
  }
  //bookmark

  return (
    <Fragment>
      {/* <Header /> */}
      <div
        className="hoeBa_kie search_background"
        style={searchBackgroundStyle}
      >
        {homeView && (
          <>
            <div
              className="d-flex flex-column gap-4 justify-content-center align-items-center heightHome mt-5"
              style={{
                height: window.innerHeight + "px",
              }}
            >
              {/* <div className="bookmark_label">
                <span
                  className="pointer"
                  onClick={() => setShowBookMarks(!showbookmarks)}
                >
                  <img src={mark} alt="" />
                  {t("bookmarks")}
                </span>
              </div> */}
              {/* {showbookmarks && (
                <div className="bookmark-Div">
                  <div className="p-2 ">
                    <span className="profile_size text-nowrap d-flex justify-content-end">
                      <MdClose className="pointer" size={22} onClick={() => setShowBookMarks(false)} />
                    </span>
                    <div >
                      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row >
                          <Nav variant="pills" className="flex-row">
                            <Col sm={3} md={6} lg={6}>
                              <Nav.Item>
                                <Nav.Link eventKey="first">{t("bookmarks")}</Nav.Link>
                              </Nav.Item>
                            </Col>
                            <Col sm={3} md={6} lg={6}>
                              <Nav.Item>
                                <Nav.Link eventKey="second">{t("history")}</Nav.Link>
                              </Nav.Item>
                            </Col>
                          </Nav>

                          <Col sm={9} md={12} lg={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="first" className="bookmark_height">
                                {storebookmark.map((item, index) => (
                                  <div key={index} className="d-flex justify-content-between align-items-center pt-1">
                                    <div className="d-flex gap-2 align-items-center">
                                      <span><img src={books} alt="" style={{ width: "32px" }} /></span>
                                      <div className="url_font d-flex flex-column">
                                        <span>{item.name}</span>
                                        <span>{item.url}</span></div>
                                    </div>
                                    <div><BsThreeDotsVertical /></div>
                                  </div>
                                ))}
                              </Tab.Pane>
                              <Tab.Pane eventKey="second" className="bookmark_height">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex align-items-center  gap-2">
                                    <input type="checkbox" name="historyRadio" placeholder="Select All" /><span style={{ fontSize: "12px" }}>Select All</span>
                                  </div>
                                  <Button
                                    label={"Delete"}
                                    color={"#fff"}
                                    backgroundcolor={"#B24BD6"}
                                    className={"buttonnew_font"}

                                  />
                                </div>
                                {history.length > 0 ? (
                                  history.map((item) => (
                                    <div key={item._id} className="d-flex justify-content-between align-items-center pt-1">
                                      <div className="d-flex gap-2 align-items-center">
                                        <input type="checkbox" name="historyRadio" id={`checkbox-${item._id}`} />
                                        <span>
                                          <img src="total" alt="" style={{ width: "32px" }} />
                                        </span>
                                        <div className="url_font">{item?.url}</div>
                                      </div>
                                      <div className="d-flex">
                                        <MdDelete className="pointer" onClick={() => handleHistoryRemove(item._id)} />
                                        <BsThreeDotsVertical className="pointer" />
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p>No history available</p>
                                )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              )} */}
              {/* {showbookmarks.bookmark && (
                <div className="bookmark-Div">
                  <div className="p-2">
                    
                    <span className="profile_size text-nowrap d-flex justify-content-between">
                    <span>{t("bookmarks")}</span>
                      <MdClose className="pointer" size={22} onClick={handlebookmarkiconclose} />
                    </span>
                    <div>
                      <div className="bookmark_height">
                        {storebookmark.map((item, index) => (
                          <div key={index} className="d-flex justify-content-between align-items-center pt-2">
                            <div className="d-flex gap-2 align-items-center">
                              <span><img src={books} alt="" style={{ width: "32px" }} /></span>
                              <div className="url_font d-flex flex-column">
                                <span>{item.name}</span>
                                <span>{item.url}</span>
                              </div>
                            </div>
                            <div><BsThreeDotsVertical /></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showbookmarks.history && (
                <div className="bookmark-Div">
                  <div className="p-2">
                    <span className="profile_size text-nowrap d-flex justify-content-between">
                    <span>{t("history")}</span>
                      <MdClose className="pointer" size={22} onClick={handlehistoryiconclose} />
                    </span>
                    <div>
                      <div className="bookmark_height">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <input type="checkbox" name="historyRadio" placeholder="Select All" /><span style={{ fontSize: "12px" }}>Select All</span>
                          </div>
                          <Button
                            label={"Delete"}
                            color={"#fff"}
                            backgroundcolor={"#B24BD6"}
                            className={"buttonnew_font"}
                          />
                        </div>
                        {history.length > 0 ? (
                          history.map((item) => (
                            <div key={item._id} className="d-flex justify-content-between align-items-center pt-2">
                              <div className="d-flex gap-2 align-items-center">
                                <input type="checkbox" name="historyRadio" id={`checkbox-${item._id}`} />
                                <span>
                                  <img src={his} alt="" style={{ width: "30px" }} />
                                </span>
                                <div className="url_font">{item?.url}</div>
                              </div>
                              <div className="d-flex">
                                <MdDelete className="pointer" onClick={() => handleHistoryRemove(item._id)} />
                                <BsThreeDotsVertical className="pointer" />
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No history available</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              <div className="d-flex flex-column align-items-center">
                <div className="search_top pb-5">{t("slogan")}</div>
                <div className="d-flex align-items-center">
                  <AiOutlineSearch size={25} className="global_search_icon" />
                  <div
                    className={
                      searchInputField
                        ? "search_history"
                        : "InputContainer px-2"
                    }
                  >
                    <input
                      type="text"
                      name="text"
                      className="input"
                      id="input"
                      placeholder={t("searchplaceholder")}
                      autoComplete="off"
                      value={searchInputField}
                      onChange={(e) => setSearchInputField(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />

                    {searchInputField && (
                      <div
                        className="labelforsearch "
                        onClick={() => {
                          setSearchInputField("");
                        }}
                      >
                        <IoClose className="pointer" size={20} />
                      </div>
                    )}

                    <div className="border"></div>

                    <div className="micButton">
                      {/* <FaMicrophone size={"1em"} /> */}

                      {!isRecording && (
                        <FaMicrophone
                          style={{
                            color: "#134ec2",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setisRecording(!isRecording);
                          }}
                          size={"20"}
                        />
                      )}
                      {isRecording && (
                        <FaRegStopCircle
                          style={{
                            color: "#134ec2",
                            cursor: "pointer",
                            marginRight: "3px",
                          }}
                          onClick={() => {
                            setisRecording(!isRecording);
                          }}
                          size={"20"}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="searchButton pointer"
                    onClick={() => {
                      if (searchInputField.trim() !== "") {
                        handleFirstSetup();
                      } else {
                        alert("Please enter a search term.");
                      }
                      setisRecording(false);
                    }}
                  >
                    {" "}
                    <img src={searchks} alt="" className="search_imgsize" />
                  </div>
                </div>
                <div className="d-flex align-items-center flex-row justify-content-center pt-1 pe-5">
                  {/* <span style={{ color: "white" }} className="language_size">
                    {t("languages")} :
                  </span> */}
                  {/* {searchlanguage.map((x, i) => (
                                        <span className='px-2 language_size pointer' onClick={() => handleLanguageClick(x.language)} > {x.language}</span>
                                    ))} */}
                  {Languages.filter((item) => item.langCode !== selectedLang).map((item, index) => (
                    <div
                      key={index}
                      onClick={() => changeLanguage(item.langCode)}
                      style={{ color: "#fff" }}
                      className="language_size pointer px-1"
                    >
                      {item.lang}
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-100 d-flex flex-column justify-content-end">
                <div className="today_font ps-5">{t("trending")}</div>
                <BiChevronLeft
                  size={25}
                  onClick={showPreviousData}
                  className="left_arrow_search pointer"
                />
                <Row className="ad_carosuel align-items-center justify-content-evenly gap-4 p-4">
                  {carosel.slice(startIndex, startIndex + itemsPerPage).map((item, index) => (
                    <Col key={index} className="d-flex inside_card" xs={12} sm={6} md={4} lg={3}>
                      <div className="margin_back">
                        <img
                          src={item.image}
                          alt=""
                          style={{ height: "auto" }}
                        />
                      </div>
                      <div style={{ lineHeight: "15px" }}>
                        <div className="head_font p-1">{item.heading}</div>
                        <div className="subhead p-1">{item.middle}</div>
                        <div className="content_font px-1">{item.content}</div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <BiChevronRight
                  size={25}
                  onClick={showNextData}
                  className="right_arrow_search pointer"
                />
              </div>

            </div>
          </>
        )}

        {homeIconView && (
          <div
            style={{
              height: window.innerHeight + "px",
            }}
          >
            <div className="d-flex flex-row justify-content-start align-items-start paddSearchIcon gap-2">
              <Row className="d-flex flex-row">
                <Col xs={12} md={7} lg={8} className="second_card gap-2 ">
                  {/* <div className='font_name '>Saroj Search</div> */}
                  <AiOutlineSearch size={22} className="global_search_icon1" />
                  <div className="InputContainer1 px-2">
                    <input
                      type="text"
                      name="text"
                      className="input"
                      id="input"
                      placeholder="Search"
                      autoComplete="off"
                      value={searchInputField}
                      onChange={(e) => {
                        setSearchInputField(e.target.value);
                      }}
                    />

                    {searchInputField && (
                      <div
                        className="labelforsearch"
                        onClick={() => {
                          setSearchInputField("");
                        }}
                      >
                        <IoClose className="pointer" size={20} />
                      </div>
                    )}

                    <div className="border"></div>

                    <div className="micButton">
                      {/* <FaMicrophone size={"1em"} /> */}

                      {!isRecording && (
                        <FaMicrophone
                          style={{
                            color: "#134ec2",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setisRecording(!isRecording);
                          }}
                          size={"1em"}
                        />
                      )}
                      {isRecording && (
                        <FaRegStopCircle
                          style={{
                            color: "#134ec2",
                            cursor: "pointer",
                            marginRight: "3px",
                          }}
                          onClick={() => {
                            setisRecording(!isRecording);
                          }}
                          size={"1em"}
                        />
                      )}
                    </div>

                    {/* <div className='searchButton' onClick={handleSearchClick}>
                                        <img src={searchks} alt="Search" className='search_imgsize' />
                                    </div> */}
                  </div>
                  <div
                    className="searchButton margin-no"
                    onClick={handleSearchClick}
                  >
                    <img
                      src={searchks}
                      alt="Search"
                      className="search_imgsize"
                    />
                  </div>
                </Col>

                <Col xs={12} md={5} lg={4} className={"second_card"}>
                  <div>
                    <div className="d-flex flex-row align-items-center justify-content-start gap-2">
                      <div
                        className={
                          showDropdown ? "back_color" : "icon_padding "
                        }
                        style={{ position: "relative" }}
                      >
                        {" "}
                        <AiOutlineSearch
                          size={23}
                          className="pointer"
                          onClick={handleSearchClick}
                        />
                        {showDropdown && (
                          <div ref={CardRef}
                            className="search-engine-list"
                            style={{
                              width: "170px",
                              position: "absolute",
                              marginTop: "15px",
                              zIndex: "1",
                            }}
                          >
                            <div className="search-box">
                              <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onBlur={() => setInputVisible(false)} // Hide input when it loses focus
                                className="Dropdown_input"
                                autoFocus="on"
                              />
                            </div>
                            <ul className="engine-list">
                              {filteredEngines.map((engine) => (
                                <li
                                  // key={index}
                                  className="engine-item"
                                  onClick={() =>
                                    handleDropdownItemClick(engine)
                                  }
                                >
                                  <img
                                    src={engine.logo}
                                    alt={`${engine.name} logo`}
                                    className="engine-logo"
                                  />
                                  <span>{engine.name}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      <div
                        className={aisearch ? "back_color" : "icon_padding "}
                        style={{ position: "relative" }}
                      >
                        {aisearch ? (
                          <img
                            src={aiwhite}
                            alt=""
                            className="img_sizing mb-1 mx-1 pointer"
                            onClick={handleAi}
                          />
                        ) : (
                          <img
                            src={ai}
                            alt=""
                            className="img_sizing mb-1 mx-1 pointer"
                            onClick={handleAi}
                          />
                        )}
                        {aisearch && (
                          <div ref={CardRef}
                            className="search-engine-list"
                            style={{
                              width: "170px",
                              position: "absolute",
                              marginTop: "15px",
                              zIndex: "1",
                            }}
                          >
                            <div className="search-box">
                              <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onBlur={() => setInputVisible(false)} // Hide input when it loses focus
                                className="Dropdown_input"
                                autoFocus="on"
                              />
                            </div>
                            <ul className="engine-list">
                              {filteredAiEngines.map((engine, index) => (
                                <li
                                  key={index}
                                  className="engine-item"
                                  onClick={() =>
                                    handleDropdownItemClick(engine)
                                  }
                                >
                                  <img
                                    src={engine.logo}
                                    alt={`${engine.name} logo`}
                                    className="engine-logo"
                                  />
                                  <span>{engine.name}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          showBookSearchCard ? "back_color" : "icon_padding "
                        }
                        style={{ position: "relative" }}
                      >
                        {showBookSearchCard ? (
                          <img
                            src={bookwhite}
                            alt=""
                            className=" pointer"
                            onClick={handleBook}
                            style={{ width: "21px" }}
                          />
                        ) : (
                          <img
                            src={booksearch}
                            alt=""
                            className=" pointer"
                            onClick={handleBook}
                            style={{ width: "20px" }}
                          />
                        )}
                        {showBookSearchCard && (
                          <div ref={CardRef}
                            className="search-engine-list"
                            style={{
                              width: "170px",
                              position: "absolute",
                              zIndex: "1",
                              marginTop: "15px",
                            }}
                          >
                            <div className="search-box">
                              <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onBlur={() => setInputVisible(false)}
                                className="Dropdown_input"
                                autoFocus="on"
                              />
                            </div>
                            <ul className="engine-list">
                              {filteredwikiEngines.map((engine, index) => (
                                <li
                                  key={index}
                                  className="engine-item"
                                  onClick={() =>
                                    handleDropdownItemClick(engine)
                                  }
                                >
                                  <img
                                    src={engine.logo}
                                    alt={`${engine.name} logo`}
                                    className="engine-logo"
                                  />
                                  <span>{engine.name}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          translatesearch ? "back_color" : "icon_padding "
                        }
                        style={{ position: "relative" }}
                      >
                        {" "}
                        <MdOutlineTranslate
                          size={23}
                          className="pointer"
                          onClick={handleTranslate}
                        />
                        {translatesearch && (
                          <div ref={CardRef}
                            className="search-engine-list"
                            style={{
                              width: "170px",
                              position: "absolute",
                              zIndex: "1",
                              marginTop: "15px",
                            }}
                          >
                            <div className="search-box">
                              <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onBlur={() => setInputVisible(false)} // Hide input when it loses focus
                                className="Dropdown_input"
                                autoFocus="on"
                              />
                            </div>
                            <ul className="engine-list">
                              {filteredTranslateEngines.map((engine, index) => (
                                <li
                                  key={index}
                                  className="engine-item"
                                  onClick={() =>
                                    handleDropdownItemClick(engine)
                                  }
                                >
                                  <img
                                    src={engine.logo}
                                    alt={`${engine.name} logo`}
                                    className="engine-logo"
                                  />
                                  <span>{engine.name}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          dictionarysearch ? "back_color" : "icon_padding "
                        }
                        style={{ position: "relative" }}
                      >
                        {dictionarysearch ? (
                          <img
                            src={diarywhite}
                            alt=""
                            className=" pointer"
                            style={{ width: "23px" }}
                            onClick={handleDictionary}
                          />
                        ) : (
                          <img
                            src={diary}
                            alt=""
                            className=" pointer"
                            style={{ width: "23px" }}
                            onClick={handleDictionary}
                          />
                        )}
                        {dictionarysearch && (
                          <div ref={CardRef}
                            className="search-engine-list dictionaryICon"
                            style={{
                              width: "170px",
                              position: "absolute",
                              zIndex: "1",
                              marginTop: "15px",
                            }}
                          >
                            <div className="search-box">
                              <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onBlur={() => setInputVisible(false)} // Hide input when it loses focus
                                className="Dropdown_input"
                                autoFocus="on"
                              />
                            </div>
                            <ul className="engine-list">
                              {filteredDicEngines.map((engine, index) => (
                                <li
                                  key={index}
                                  className="engine-item"
                                  onClick={() =>
                                    handleDropdownItemClick(engine)
                                  }
                                >
                                  <img
                                    src={engine.logo}
                                    alt={`${engine.name} logo`}
                                    className="engine-logo"
                                  />
                                  <span>{engine.name}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          showNewsCard ? "back_color" : "icon_padding "
                        }
                        style={{ position: "relative" }}
                      >
                        {showNewsCard ? (
                          <img
                            src={newswhite}
                            alt=""
                            className="img_resizing pointer"
                            onClick={handleNews}
                          />
                        ) : (
                          <img
                            src={news}
                            alt=""
                            className="img_resizing pointer"
                            onClick={handleNews}
                          />
                        )}
                        {showNewsCard && (
                          <div ref={CardRef}
                            className="search-engine-list dictionaryICon"
                            style={{
                              width: "170px",
                              position: "absolute",
                              zIndex: "1",
                              marginTop: "15px",
                            }}
                          >
                            <div className="search-box">
                              <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onBlur={() => setInputVisible(false)} // Hide input when it loses focus
                                className="Dropdown_input"
                                autoFocus="on"
                              />
                            </div>
                            <ul className="engine-list">
                              {filterednewsEngines.map((engine, index) => (
                                <li
                                  key={index}
                                  className="engine-item"
                                  onClick={() =>
                                    handleDropdownItemClick(engine)
                                  }
                                >
                                  <img
                                    src={engine.logo}
                                    alt={`${engine.name} logo`}
                                    className="engine-logo"
                                  />
                                  <span>{engine.name}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="bookmark_label">
                    <span
                      className="pointer"
                      onClick={() => setShowBookMarks(!showbookmarks)}
                    >
                      <img src={mark} alt="" />
                      {t("bookmarks")}
                    </span>
                  </div> */}
                  {/* {showbookmarks && (
                    <div className="bookmark-Div">
                      <div className="p-2 ">
                        <span className="profile_size text-nowrap border-bottom">
                          {t("bookmarks")}
                        </span>
                        <div className="bookmark_height">
                          {bookmark.map((x, i) => (
                            <div className="">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <BsBookmarkFill /> {x?.name}
                                </div>
                                <MdArrowOutward />
                              </div>
                              <div
                                style={{ fontSize: "10px", fontWeight: "400" }}
                              >
                                {x?.view}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )} */}
                </Col>
              </Row>
            </div>

            {fullView && (
              <>
                <div className="row d-flex justify-content-center">
                  {expandedIndex === null && Browsers.length === 1 && (
                    <div className="col-2 d-flex align-items-center">
                      <div className="web">
                        <div>
                          <img src={gif1} width={"100%"} />
                        </div>

                        <div>
                          <img src={gif2} width={"100%"} />
                        </div>
                      </div>
                    </div>
                  )}

                  {Browsers.length > 2 && (
                    <div
                      className="col-1 d-flex justify-content-center align-items-center"
                      style={{
                        flex: "0 0 auto",
                        width: "2%",
                      }}
                    >
                      {/* <IoMdArrowDropleft size={"25px"} color='#B24BD6' /> */}
                      <img src={prev} alt="" style={{ cursor: "pointer" }} onClick={handleIframePre} />
                    </div>
                  )}

                  {Browsers.slice(initialset, initialset + itempage).map((browser, index) => {
                    if (index === 0 || index === 1) {
                      // const isExpanded = expandedIndex === index;
                      // const colClass = isExpanded ? 'col-12' : `col-${Browsers.length > 1 ? 6 : 8} p-1`;
                      // const isExpanded = expandedIndex === index + 1;
                      // const colClass = isExpanded ? 'col-12' : 'col-6';
                      return (
                        <>
                          <IframeView
                            index={index}
                            browser={browser}
                            Browsers={Browsers}
                            expandedIndex={expandedIndex}
                            isMobile={isMobile}
                            handleExpand={handleExpand}
                            handleClose={handleClose}
                            handleBookmark={() => handlebookmarks(index, browser)}
                            isClicked={browser.bookmarked}
                          // key={browser}
                          // sharemodal={sharemodal}
                          />
                        </>
                      );
                    } else {
                      return null; // Return null if the condition is not met
                    }
                  })}
                  {expandedIndex === null && Browsers.length === 1 && (
                    <div className="col-2 d-flex align-items-center">
                      <div className="web">
                        <div>
                          <img src={gif3} width={"100%"} />
                        </div>

                        <div onClick={() => navigate("/iframe")}>
                          <img src={gif4} width={"100%"} />
                        </div>

                        <div>
                          <img src={gif5} width={"100%"} />
                        </div>
                      </div>
                    </div>
                  )}

                  {Browsers.length > 2 && (
                    <div
                      className="col-1 d-flex justify-content-center align-items-center"
                      style={{
                        flex: "0 0 auto",
                        width: "2%",
                      }}
                    >
                      {/* <IoMdArrowDropright size={"25px"} color='#B24BD6' /> */}
                      <img src={next} alt="" style={{ cursor: "pointer" }} onClick={handleIframeNext} />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {showbookmarks.bookmark && (
          <div ref={CardRef} className="bookmark-Div">
            <div className="p-2">

              <span className="profile_size text-nowrap d-flex justify-content-between">
                <span>{t("bookmarks")}</span>
                <MdClose className="pointer" size={22} onClick={handlebookmarkiconclose} />
              </span>
              <div>
                <div className="bookmark_height">
                  {storebookmark.map((item, index) => (
                    <div key={index} className="d-flex justify-content-between align-items-center pt-2">
                      <div className="d-flex gap-2 align-items-center">
                        <span><img src={books} alt="" style={{ width: "32px" }} /></span>
                        <div className="url_font d-flex flex-column">
                          <span>{item.name}</span>
                          <span>{item.url}</span>
                        </div>
                      </div>
                      <div>
                        <MdDelete className="pointer" onClick={() => handleBookmarkRemove(item._id)} />
                        <BsThreeDotsVertical />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {showbookmarks.history && (
          <div ref={CardRef} className="bookmark-Div">
            <div className="p-2">
              <span className="profile_size text-nowrap d-flex justify-content-between">
                <span>{t("history")}</span>
                <MdClose className="pointer" size={22} onClick={handlehistoryiconclose} />
              </span>
              <div>
                <div className="bookmark_height">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      {/* <input type="checkbox" name="historyRadio" placeholder="Select All" /><span style={{ fontSize: "12px" }}>Select All</span> */}
                    </div>
                    <Button
                      label={"Delete All"}
                      color={"#fff"}
                      backgroundcolor={"#B24BD6"}
                      className={"buttonnew_font"}
                      onClick={handleClearAllHistory}
                    />
                  </div>
                  {history?.length > 0 ? (
                    history.map((item) => (
                      <div key={item._id} className="d-flex justify-content-between align-items-center pt-2">
                        <div className="d-flex gap-2 align-items-center">
                          {/* <input type="checkbox" name="historyRadio" id={`checkbox-${item._id}`} /> */}
                          <span>
                            <img src={his} alt="" style={{ width: "30px" }} />
                          </span>
                          <div className="url_font">{item?.url}</div>
                        </div>
                        <div className="d-flex">
                          <MdDelete className="pointer" onClick={() => handleHistoryRemove(item._id)} />
                          <BsThreeDotsVertical className="pointer" />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No history available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Search;
