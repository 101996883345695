import React, { useEffect, useRef, useState } from "react";
import logo from "../Assets/applogo.png";
import logo_us from "../Assets/twemoji_lotus (1).png";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { MdCall, MdEmail, MdClose, MdPrivacyTip, MdHistory, MdFeedback, MdKeyboardArrowDown, MdBookmark } from "react-icons/md";
import { PostLogout } from "../Services/Login";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosCloseCircle } from "react-icons/io";
import DefaultProfilePic from "../Assets/man.png";
import Modalc from "../Common/Modalc";
import sideimg from "../Assets/AA.png";
import Select from "../Common/Select";
import Input from "../Common/Input";
import Input1 from "../Common/Input1";
import Button1 from "../Common/Button1";
import { GetAllCountry, GetAllLanguage } from "../Services/Signup";
import { toast } from "react-toastify";
import {
  isMobile,
  isTablet,
  isBrowser,
  browserName,
  browserVersion,
} from "react-device-detect";
import { v4 as uuid } from "uuid";
import { Form } from "react-bootstrap";
import {
  CheckIsMobileNoExist,
  FetchKpostIDDetails,
  GetKpostIdSuggestion,
  PostLogin,
  SendOTP,
  UserSignup,
  ValidateOTP,
  postalPinCode,
} from "../Services/Login";
import Button from "../Common/Button1";
import { BsArrowLeft, BsCircleHalf } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { IoClose, IoSettingsOutline } from "react-icons/io5";
// import { t } from 'i18next';
import { useTranslation } from "react-i18next";
import { BiSolidBrush } from "react-icons/bi";

const Header1 = ({ modalshow, countryAll, setcountryAll, CountryGetAll, setModalShow,handlebookmarkshow,handlehistory,showbookmarks,setShowBookMarks,showhistory,setShowHistory }) => {
  const navigate = useNavigate();
  const AuthUser = localStorage.getItem("Authuser");
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setProfileCard(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const gotoSignup = () => {
    navigate("/signup");
  };

  const gotoSignIn = () => {
    navigate("/login");
  };

  const location = useLocation();

  const [activeLink, setActiveLink] = useState(location.pathname);
  const [profilecard, setProfileCard] = useState(false);

  const handleClick = (path) => {
    setActiveLink(path);
    setNavVisible(false); // Close menu when navigating
  };

  const [navVisible, setNavVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  const toggleNav = () => {
    if (navVisible) {
      setAnimationClass("slide-out");
      setTimeout(() => {
        setNavVisible(false);
        setAnimationClass("");
      }, 500); // Duration of the slide-out animation
    } else {
      setNavVisible(true);
      setAnimationClass("slide-in");
    }
  };

  const handleLogout = async () => {
    const DeviceId = localStorage.getItem("deviceIdentity_primary");

    let bod = {
      deviceType: "Web",
      deviceIdentityPrimary: DeviceId,
      logouttime: Date.now(),
    };

    console.log("bod", bod);

    try {
      let response = await PostLogout(bod);
      console.log(response,"logout");
      if (response.status.toLowerCase() === "success") {
        setProfileCard(false);
        navigate("/");
        localStorage.clear();
      } else {
        // Handle error response
        console.error("Logout request failed:", response);
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error during logout:", error);
    }
  };

  // signup
  const [signupBoolean, setsignupBoolean] = useState({
    signupPage: true,
    createPage: false,
    verifyNumber: false,
    modalPhoneNumber: false,
    modalSuggestion: false,
    modalKpost: false,
    isValid: true,
    category: true,
    vertical: false,
    newpage: false,
    confirmpassword: false,
  });

  const [country, setCountry] = useState("");
  const [countrySignin, setCountrySignin] = useState("");
  const [language, setLanguage] = useState("");
  const [languageAll, setlanguageAll] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setemailError] = useState("");

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [signupGender, setSignupGender] = useState("");
  const [areas, setAreas] = useState([]);
  const [areasData, setAreasData] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const initialOtp = ["", "", "", "", "", ""];
  const [otpNumber1, setOtpNumber1] = useState(initialOtp);
  const [modalShow, setmodalShow] = useState({
    show: false,
    pinCodeModal: false,
  });
  const [suggestList, setSuggestList] = useState([]);
  const [kpostid, setKpostid] = useState("");
  const [kpostidUser, setKpostidUser] = useState("");
  const [preferred, setPreferred] = useState("");
  const [password, setPassword] = useState("");
  const [cnfrmpassword, setCnfrmPassword] = useState("");
  const [suggestionmodal, setSuggestionModal] = useState(false);
  const [settingcard, setSettingCard] = useState(false);
  const CardRef = useRef(null);

  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - 18);

  const formattedMaxDate = maxDate.toISOString().split("T")[0]; // Format maxDate as YYYY-MM-DD

  const handleSuggestionClick = (suggestion) => {
    setKpostid(`${suggestion}@kpostindia.com`);
  };

  const handleGetSuggestions = async () => {
    const body = { firstName, lastName, mobileNumber };
    try {
      const response = await GetKpostIdSuggestion(body);
      setSuggestList(response.data);
      setsignupBoolean({
        ...signupBoolean,
        modalSuggestion: true,
      });
      setmodalShow(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];


  const LanguageGetAll = async (countryId) => {
    setlanguageAll([]);
    let bod = {
      countryID: countryId,
    };

    let response = await GetAllLanguage(bod);
    if (response?.status === "SUCCESS") {
      if (response.data.length > 0) {
        let dataOPtion = response.data.map((e) => {
          return {
            ...e,
            label: `${e.language}`,
            value: `${e.id}`,
          };
        });
        setlanguageAll(dataOPtion);
      }
    } else {
      setlanguageAll([]);
    }
  };

  const handleClose = () => {
    setmodalShow({ ...modalShow, show: false });
    setsignupBoolean({ ...signupBoolean, modalSuggestion: false });
    setOtpNumber1(initialOtp);
  };

  const Mobile = (e) => {
    const input = e.target.value;
    const NumberRegex = /^\d{0,10}$/;
    if (NumberRegex.test(input)) {
      if (input.length < 10) {
        setIsEmailReadOnly(false);
        setFirstName("");
        setLastName("");
      }

      if (input.length === 10) {
        sendOTP(input);
        setsignupBoolean({ ...signupBoolean, modalPhoneNumber: true });
      }
      setMobileNumber(input);
    }
  };

  const [timer, setTimer] = useState(null);
  const [disableResend, setDisableResend] = useState(true);

  const [isEmailReadOnly, setIsEmailReadOnly] = useState(false);
  const [isPincodeReadOnly, setIsPincodeReadOnly] = useState(false);

  const [mobileRead, setmobileRead] = useState(false);

  const [resend, setresend] = useState(true);

  const sendOTP = async (mobileNumber) => {
    const enteredNumber = mobileNumber;

    try {
      const existsObj = {
        countryID: country.countryID,
        mobileNumber: mobileNumber,
      };

      const response = await CheckIsMobileNoExist(existsObj);

      if (response?.statusCode === 200 && response.data === true) {
        toast.error("Mobile number already exists!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        let res = await FetchKpostIDDetails({
          kpostID: mobileNumber,
        });
        if (res && res.status === "SUCCESS") {
          setFirstName(res.data.firstName);
          setLastName(res.data.lastName);
          setKpostidUser(res.data.kpostID);
          setIsEmailReadOnly(true);
        } else {
          setFirstName("");
          setLastName("");
          setKpostidUser("");
          setIsEmailReadOnly(false);
        }
      } else if (response?.statusCode === 200) {
        const bod = {
          countryID: country.countryID,
          mobileNumber: mobileNumber,
          requestType: "signup",
        };

        const otpResponse = await SendOTP(bod);
        if (otpResponse?.status === "SUCCESS") {
          setmodalShow({ ...modalShow, show: true });
          setsignupBoolean({ ...signupBoolean, modalPhoneNumber: true });
          toast.success(otpResponse?.message);
          setTimer(30);
          setDisableResend(true);
          const interval = setInterval(() => {
            setTimer((prev) => (prev > 0 ? prev - 1 : null));
          }, 1000);
          setmodalShow({ ...modalShow, show: true });
          setTimeout(() => {
            clearInterval(interval);
            setDisableResend(false);
          }, 30000);
        } else {
          toast.error(otpResponse?.message);
        }
      } else {
        toast.error("Mobile number already exists!");
        let res = await FetchKpostIDDetails({
          kpostID: mobileNumber,
        });
        if (res && res.status === "SUCCESS") {
          setFirstName(res.data.firstName);
          setLastName(res.data.lastName);
          setKpostidUser(res.data.kpostID);
          setIsEmailReadOnly(true);
        } else {
          setFirstName("");
          setLastName("");
          setKpostidUser("");
          setIsEmailReadOnly(false);
        }
      }
    } catch (error) {
      // console.error("Error in CheckIsMobileNoExist:", error);
      toast.error("Error checking mobile number existence");
    }
  };

  const inputRefs = useRef([]);

  const handleChnageOtp = (index, e) => {
    let inputOtp = e.target.value;
    inputOtp = inputOtp.replace(/\D/g, "");

    // Update state
    const newOtpNumber1 = [...otpNumber1];
    newOtpNumber1[index] = inputOtp;
    setOtpNumber1(newOtpNumber1);

    // Move focus to the next input if the current input is not empty
    if (inputOtp && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Move focus to the previous input if the current input is empty
    if (!inputOtp && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    // Do further operations if needed
    if (newOtpNumber1.join("").length === 6) {
      // Do something
    }
  };

  const validateOTP = async (valotp, mobileNumber) => {
    try {
      const otpNumber1 = Array.isArray(valotp) ? valotp.join("") : valotp;
      const bod = {
        otp: otpNumber1,
        countryID: country.countryID,
        mobileNumber: mobileNumber,
        sendDate: Date.now(),
      };

      const response = await ValidateOTP(bod);

      if (response?.status === "SUCCESS") {
        toast.success(response?.message);
        handleClose();
        setmobileRead(true);
        setotpverifyForName(true);
        setsignupBoolean({ ...signupBoolean, verifyNumber: true });
      } else {
        toast.error("Invalid OTP. Please enter the correct code");
        setmobileRead(false);
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
    }
  };

  const [otpverifyForName, setotpverifyForName] = useState(false);
  const handleOtpSubmit = () => {
    if (otpNumber1.filter((x) => x !== "").length === 6) {
      console.log("OTP is complete and ready for submission");
      const newOtpNumber1 = [...otpNumber1];
      validateOTP(newOtpNumber1.join(""), mobileNumber);
      // Proceed with OTP submission
    } else {
      console.log("OTP is incomplete");
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailValue)) {
      setemailError("Invalid email address");
    } else {
      setemailError("");
    }
  };

  const PincodeModal = async (e) => {
    const input = e.target.value.trim();
    if (input.length <= 6 && /^\d{0,6}$/.test(input)) {
      setPinCode(input);
      if (input.length === 6) {
        const response = await postalPinCode({ postalCode: input });
        if (response && response.status === "SUCCESS") {
          setIsPincodeReadOnly(true);
          setAreas(response.data[0].areaName);
        } else {
          setAreas([]);
        }
      } else {
        setIsPincodeReadOnly(false);
      }
    }
  };

  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleConfirmPasswordChange = (e) => {
    const confirmPwd = e.target.value;
    setCnfrmPassword(confirmPwd);
    setPasswordMatch(password === confirmPwd);
  };

  const SignUpHandle = async () => {
    let DOB = moment(dateOfBirth).format("YYYY-MM-DD");
    try {
      let SignUpObj = {
        kpostID: kpostid,
        firstName,
        lastName,
        mobileNumber,
        createdDate: Date.now(),
        password: cnfrmpassword,
        gender: signupGender.value,
        dateOfBirth: DOB,
        countryCode: country.countryCode,
        userProfile: {
          landLineNumber: "",
          referalId: "",
          pinCode: pinCode,
          areaName: areasData.label,
        },
      };
      let response = await UserSignup(SignUpObj);
      if (response?.status === "SUCCESS") {
        toast.success(response.status);
        handleClose();
        // navigate('/login')
        setModalShow(true);
        setsignupmodal(false);
      } else {
        toast.error(response.error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [passwordIn, setPasswordIn] = useState("");

  const [userName, setuserName] = useState("");
  const [loginpassword, setLoginpassword] = useState("");

  const device_uuid = uuid();
  const device_name = isMobile
    ? "Mobile"
    : isTablet
      ? "Tablet"
      : isBrowser
        ? "Desktop"
        : "Unknown";
  const DeviceWithversion =
    device_name + "-" + browserName + "-" + browserVersion;

  const handleLogin = async () => {
    let bod = {
      loginRO: {
        countryID: countrySignin.countryID,
        password: loginpassword,
      },
      kpostID: userName,
      sessionID: device_uuid,
      deviceType: "Web",
      deviceIdentity_primary: device_uuid,
      deviceIdentity_secondary: DeviceWithversion,
      oneSignal_Key: "oneSignalKey",
      logintime: Date.now(),
    };
    try {
      let response = await PostLogin(bod);
      if (response.status.toLowerCase() === "success") {
        const sessionTimeout = 3600 * 1000; // 1 hour in milliseconds
        const currentTime = Date.now();
        navigate("/search");
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("Authuser", JSON.stringify(response.data));
        localStorage.setItem("deviceIdentity_primary", device_uuid);
        localStorage.setItem("loginTime", currentTime); // Save login time
        localStorage.setItem("sessionTimeout", sessionTimeout); // Save session timeout duration
        toast.success(response?.message);
      } else {
        toast.error("Incorrect username or password. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred during login. Please try again.");
    }
  };

  const handlesignupLogin = async () => {
    let bod = {
      loginRO: {
        countryID: country.countryID,
        password: passwordIn,
      },
      kpostID: mobileNumber,
      sessionID: device_uuid,
      deviceType: "Web",
      deviceIdentity_primary: device_uuid,
      deviceIdentity_secondary: DeviceWithversion,
      oneSignal_Key: "oneSignalKey",
      logintime: Date.now(),
    };
    try {
      let response = await PostLogin(bod);
      if (response.status.toLowerCase() === "success") {
        const sessionTimeout = 3600 * 1000; // 1 hour in milliseconds
        const currentTime = Date.now();
        navigate("/search");
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("Authuser", JSON.stringify(response.data));
        localStorage.setItem("deviceIdentity_primary", device_uuid);
        localStorage.setItem("loginTime", currentTime); // Save login time
        localStorage.setItem("sessionTimeout", sessionTimeout); // Save session timeout duration
        toast.success(response?.message);
      } else {
        toast.error("Incorrect username or password. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred during login. Please try again.");
    }
  };

  const handleSessionExpiration = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("Authuser");
    localStorage.removeItem("deviceIdentity_primary");
    localStorage.removeItem("loginTime");
    localStorage.removeItem("sessionTimeout");
    toast.error("Session has expired. Please log in again.");
    // navigate("/login");
    setModalShow(true);
  };

  // Call this function to check session validity on page load or user actions
  const checkSessionValidity = () => {
    const loginTime = localStorage.getItem("loginTime");
    const sessionTimeout = localStorage.getItem("sessionTimeout");
    const currentTime = Date.now();

    if (loginTime && sessionTimeout) {
      const elapsedTime = currentTime - loginTime;
      if (elapsedTime > sessionTimeout) {
        handleSessionExpiration();
      }
    }
  };

  // Example usage: Call checkSessionValidity on page load or user actions
  window.onload = checkSessionValidity;

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      // If the value is a number, limit the length to 10
      if (value.length <= 10) {
        setuserName(value);
      }
    } else {
      // If the value is text, allow any length
      setuserName(value);
    }
  };

  const [singupmodal, setsignupmodal] = useState(false);

  const signupModal_valueEmpty = () => {
    setsignupBoolean({
      ...signupBoolean,
      signupPage: true,
      createPage: false,
      confirmpassword: false,
    });
    setCountry("");
    setLanguage("");
    setPasswordIn("");
    setMobileNumber("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setSignupGender("");
    setDateOfBirth("");
    setPinCode("");
    setAreasData("");
    setKpostid("");
    setKpostidUser("");
    setPassword("");
    setmobileRead(false);
    setIsPincodeReadOnly(false);
    setIsEmailReadOnly(false);
  };

  // Login
  const Authuser = JSON.parse(localStorage.getItem(`Authuser`));

  const { t, i18n } = useTranslation();

  const handlebookmarknew = () =>{
    setShowBookMarks({showbookmarks, bookmark:true,history:false });  
    setSettingCard(false);
    setProfileCard(false);
  }

  const handlehistorynew = () =>{
    setShowBookMarks({showbookmarks, history:true, bookmark:false });
    setSettingCard(false);
    setProfileCard(false);
  }

  const handlesettingcard = ()=>{
    setSettingCard(!settingcard);
    setProfileCard(false);
    setShowBookMarks({showbookmarks, history:false, bookmark:false });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (CardRef.current && !CardRef.current.contains(event.target)) {
        setSettingCard(false);
        setProfileCard(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="web">
        <div className="d-flex justify-content-between p-1 headBackie_Ho">
          <div className="d-flex justify-content-start align-items-center">
            <div className=" ps-3">
              <img src={logo} alt="" style={{ width: "46%" }} />
            </div>

            <div className="Navssss">
              <Link
                to="/"
                className={activeLink === "/" ? "active" : ""}
                onClick={() => handleClick("/")}
              >
                {t("home")}
              </Link>
              <Link
                to="/products"
                className={activeLink === "/products" ? "active" : ""}
                onClick={() => handleClick("/products")}
              >
                {t("product")}
              </Link>
              <Link
                to="/support"
                className={activeLink === "/support" ? "active" : ""}
                onClick={() => handleClick("/support")}
              >
                {t("support")}
              </Link>
              <Link
                to="/#"
                className={activeLink === "/news" ? "active" : ""}
                onClick={() => handleClick("/news")}
              >
                {t("recentnews")}
              </Link>
              <Link
                to="/#"
                className={activeLink === "/settings" ? "active" : ""}
                onClick={() => handleClick("/settings")}
              >
                {t("settings")}
              </Link>
              <Link
                to="/about"
                className={activeLink === "/about" ? "active" : ""}
                onClick={() => handleClick("/about")}
              >
                {t("about")}
              </Link>
              <Link
                to="/contact"
                className={activeLink === "/contact" ? "active" : ""}
                onClick={() => handleClick("/contact")}
              >
                {t("contact")}
              </Link>
            </div>
          </div>

          <div className="pe-3 d-flex align-items-center">
            {!AuthUser && (
              <>
                <div
                  className="loginbtn"
                  onClick={() => {
                    setModalShow(true);
                    CountryGetAll();
                  }}
                  style={{ zIndex: "2" }}
                >
                  SignIn
                </div>
                &nbsp;&nbsp;
                <div
                  className="loginbtn"
                  onClick={() => {
                    setsignupmodal(true);
                    CountryGetAll();
                    signupModal_valueEmpty();
                  }}
                >
                  SignUp
                </div>
              </>
            )}

            {AuthUser && (
              <div className="d-flex align-items-center gap-3">
                <div>
                  <IoSettingsOutline size={22} className=" pointer" onClick={handlesettingcard} />
                  {settingcard && (
                    <div ref={CardRef} className="settingcard">
                      <div className="p-2 border-bottom d-flex justify-content-between">
                        <span className="profile_size text-nowrap">{t("settings")}</span>
                        <span><MdClose className=" pointer" onClick={handlesettingcard} /></span>
                      </div>
                      <div className="d-flex flex-column gap-2 p-2">
                      <div onClick={handlebookmarknew}><MdBookmark className="pointer"/> <span className="ps-2 pointer">Bookmark</span></div>
                        <div onClick={()=> handlehistorynew()}><MdHistory className="pointer"/> <span className="ps-2 pointer">History</span></div>
                        <div><MdPrivacyTip className="pointer"/> <span className="ps-2 pointer">Privacy</span></div>
                        <div><MdFeedback className="pointer"/> <span className="ps-2 pointer">Feedback</span></div>
                        <div className="d-flex align-items-center justify-content-between pointer"><div><span className="pe-2"><BsCircleHalf /> </span>Appearance</div> <MdKeyboardArrowDown /></div>
                        <div className="d-flex align-items-center justify-content-between pointer"><div><span className="pe-2"><BiSolidBrush /> </span>Themes</div> <MdKeyboardArrowDown /></div>
                      </div>
                    </div>
                  )}
                </div>
                <img
                  src={`https://devapi2.kpostindia.com/v2/profile/downloadProfileImage/${Authuser.kpostID}`}
                  alt=""
                  onError={(e) => {
                    e.target.src = DefaultProfilePic;
                  }}
                  className="profile_image pointer"
                  onClick={() => {
                    setProfileCard(!profilecard);
                    setSettingCard(false);
                  }} 
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mobi">
        <div className="w-100 d-flex justify-content-between align-items center">
          <div
            className="ps-2 py-2"
            onClick={() => {
              toggleNav();
            }}
          >
            {navVisible ? (
              <IoIosCloseCircle size={"24"} />
            ) : (
              <RxHamburgerMenu size={"24"} />
            )}
          </div>

          <div className="d-flex justify-content-center">
            <img src={logo_us} alt="" style={{ width: "20%" }} />
          </div>
          <div className="pe-3 d-flex align-items-center">
            {!AuthUser && (
              <>
                <div className="loginbtn" onClick={() => setModalShow(true)}>
                  SignIn
                </div>
                &nbsp;&nbsp;
                <div className="loginbtn" onClick={() => setsignupmodal(true)}>
                  SignUp
                </div>
              </>
            )}

            {AuthUser && (
              <img
                src={`https://devapi2.kpostindia.com/v2/profile/downloadProfileImage/${Authuser.kpostID}`}
                alt=""
                className="profile_image pointer"
                onError={(e) => {
                  e.target.src = DefaultProfilePic;
                }}
                onClick={() => {
                  setProfileCard(!profilecard);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {profilecard && (
        <div ref={CardRef} className="Arrow-Div">
          <div className="p-2 border-bottom">
            <span className="profile_size  text-nowrap">{t("profile")}</span>
          </div>
          <div className="d-flex align-items-center p-2 gap-1 ">
            <img
              src={`https://devapi2.kpostindia.com/v2/profile/downloadProfileImage/${Authuser.kpostID}`}
              onError={(e) => {
                e.target.src = DefaultProfilePic;
              }}
              alt=""
              className="profile_image"
            />
            <span className="d-flex align-items-center font_size">
              {Authuser.firstName} {Authuser.lastName}
            </span>
          </div>
          <div>
            <div className="p-2">
              <MdCall size={22} />
              <span className="font_size ps-3">{Authuser.mobileNumber}</span>
            </div>
            <div className="p-2">
              <MdEmail size={22} />
              <span className="font_size ps-3">{Authuser.email}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center pb-3">
              <button
                className="p-1 pointer Logout_btn w-75"
                onClick={handleLogout}
              >
                {t("logout")}
              </button>
            </div>
          </div>
        </div>
      )}
      {navVisible && (
        <div className={`Navssss ${animationClass}`}>
          <Link
            to="/"
            className={classNames({ active: activeLink === "/" })}
            onClick={() => handleClick("/")}
          >
            {t("home")}
          </Link>
          <Link
            to="/#"
            className={classNames({ active: activeLink === "/products" })}
            onClick={() => handleClick("/products")}
          >
            {t("products")}
          </Link>
          <Link
            to="/#"
            className={classNames({ active: activeLink === "/support" })}
            onClick={() => handleClick("/support")}
          >
            {t("support")}
          </Link>
          <Link
            to="/#"
            className={classNames({ active: activeLink === "/news" })}
            onClick={() => handleClick("/news")}
          >
            {t("recentnews")}
          </Link>
          <Link
            to="/#"
            className={classNames({ active: activeLink === "/settings" })}
            onClick={() => handleClick("/settings")}
          >
            {t("settings")}
          </Link>
          <Link
            to="/#"
            className={classNames({ active: activeLink === "/about" })}
            onClick={() => handleClick("/about")}
          >
            {t("about")}
          </Link>
          <Link
            to="/#"
            className={classNames({ active: activeLink === "/contact" })}
            onClick={() => handleClick("/contact")}
          >
            {t("contact")}
          </Link>
        </div>
      )}

      <Modalc
        show={modalshow}
        onClick={() => setModalShow(false)}
        leftcontent={
          <div className="w-100">
            <img src={sideimg} alt="" className="sideimg_size" />
          </div>
        }
        rightcontent={
          <>
            <div className="mb-3 w-100">
              <div
                className="d-flex justify-content-end pointer"
                onClick={() => setModalShow(false)}
              >
                <IoClose size={"20px"} />
              </div>
              <h3 className="fw-bold mb-2 ">SignIn</h3>
              <p className=" ">Enter SignIn Details</p>
              {/* <Form className=""
                                            onSubmit={handleLogin}
                                            autoComplete="on"
                                        > */}

              <Select
                label={"Country"}
                placeholder={"Choose Country"}
                options={countryAll}
                value={countrySignin}
                className={"mb-3"}
                onChange={(e) => {
                  setCountrySignin(e);
                }}
              />

              <Input
                label="User ID / Mobile Number"
                type="text"
                placeholder="Enter User ID / Mobile Number"
                name="Enter User ID / Mobile Number"
                className={"mb-3"}
                otpOnChange={handleInputChange}
                value={userName}
                readOnly={!countrySignin}
              />
              {/* <div className='text-end need_size pt-1'>
                                            Need Help
                                        </div> */}

              <Input
                label="Password"
                type="password"
                placeholder="Enter Password"
                name="password"
                className={"mb-3"}
                otpOnChange={(e) => {
                  setLoginpassword(e.target.value);
                }}
                value={loginpassword}
                readOnly={!userName}
                showicon={true}
                iconStyle={{
                  position: "relative",
                  bottom: "48px",
                  left: "91%",
                }}
              />

              {/* <div className="mb-3">
                                            <p className="small">
                                                <a className="text-primary" href="#!">
                                                    Need help ?
                                                </a>
                                            </p>
                                        </div> */}
              <Button1
                onClick={() => {
                  handleLogin();
                }}
                className="Button_Style Button_Font mt-3"
                disabled={loginpassword ? false : true}
                backgroundcolor={loginpassword ? "#B24BD6" : "#E9D3F3"}
                color={"#fffff"}
                label={"Submit"}
              />
              <div className="text-center pt-1">
                Need an Account?{" "}
                <span
                  className="need_size"
                  onClick={() => {
                    setsignupmodal(true);
                    setModalShow(false);
                  }}
                >
                  Sign Up
                </span>
              </div>
              {/* </Form> */}
            </div>
          </>
        }
      />
      <Modalc
        show={singupmodal}
        onClick={() => setsignupmodal(false)}
        leftcontent={
          <div className="w-100">
            <img src={sideimg} alt="" className="sideimg_size" />
          </div>
        }
        className={"flex-column"}
        rightcontent={
          <>
            {signupBoolean.signupPage && (
              <>
                <div className="height_sete w-100 pt-3">
                  <div className="d-flex flex-column ">
                    {!isEmailReadOnly && (
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="Signup_Font d-flex flex-column justify-content-between ">
                            Sign Up
                          </div>
                          <div className="pointer" onClick={() => setsignupmodal(false)}>
                            <IoClose size={20} />
                          </div>
                        </div>
                        <div className="KP_Font">Sarojini Spectrum</div>
                        <div className="head_size">Enter Your Details</div>
                      </div>
                    )}
                    {isEmailReadOnly && (
                      <div className="d-flex flex-column gap-1">
                        <div className="Signup_Font d-flex flex-column justify-content-between ">
                          Sign In
                        </div>
                        <div className="head_size">Enter SignIn Details</div>
                      </div>
                    )}

                    <div className="d-flex flex-column justify-content-between">
                      <div className="d-flex flex-column gap-2 mt-3">
                        {!isEmailReadOnly && (
                          <>
                            <Select
                              label={"Country"}
                              placeholder={"Choose Country"}
                              isDropdown
                              options={countryAll}
                              value={country}
                              onChange={(e) => {
                                setCountry(e);
                                LanguageGetAll(e.value);
                              }}
                            />

                            <Select
                              label={"Language"}
                              placeholder={"Choose Language"}
                              isDropdown
                              options={languageAll}
                              value={language}
                              onChange={(e) => {
                                setLanguage(e);
                              }}
                              isDisabled={country ? false : true}
                            />
                          </>
                        )}

                        {isEmailReadOnly && (
                          <>
                            <Select
                              label={"Country"}
                              placeholder={"Choose Country"}
                              isDropdown
                              options={countryAll}
                              value={country}
                              onChange={(e) => {
                                setCountry(e);
                                LanguageGetAll(e.value);
                              }}
                              isDisabled
                            />

                            <Input
                              label="User ID / Mobile Number"
                              required={"*"}
                              type="text"
                              placeholder="Enter Preferred User ID"
                              name="Preferred User ID"
                              value={kpostidUser}
                              otpOnChange={(e) => {
                                setKpostidUser(e.target.value);
                              }}
                              readOnly
                            />

                            <Input
                              label="Password"
                              type="password"
                              placeholder="Enter Password"
                              name="password"
                              className={"mb-3"}
                              otpOnChange={(e) => {
                                setPasswordIn(e.target.value);
                              }}
                              value={passwordIn}
                              showicon={true}
                              iconStyle={{
                                position: "relative",
                                bottom: "48px",
                                left: "91%",
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isEmailReadOnly ? (
                  <Button
                    onClick={() => {
                      handlesignupLogin();
                    }}
                    className="Button_Font Button_Style"
                    disabled={language ? false : true}
                    backgroundcolor={"#B24BD6"}
                    color={"#fffff"}
                    label={"Submit"}
                  />
                ) : (
                  <Button
                    onClick={() => {
                      setsignupBoolean({
                        ...signupBoolean,
                        signupPage: false,
                        createPage: true,
                      });
                      if (!signupBoolean.verifyNumber) {
                        setMobileNumber("");
                      }
                      setFirstName("");
                      setLastName("");
                    }}
                    className="Button_Font Button_Style"
                    disabled={language ? false : true}
                    backgroundcolor={language ? "#B24BD6" : "#E9D3F3"}
                    color={"#fffff"}
                    label={"Continue"}
                  />
                )}

                <div className="d-flex flex-column pb-3">
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    Already Have an Account? &nbsp;{" "}
                    <span
                      onClick={() => {
                        setModalShow(true);
                        setsignupmodal(false);
                      }}
                      className="need_size"
                    >
                      {" "}
                      SignIn
                    </span>
                  </div>
                </div>
              </>
            )}
            {signupBoolean.createPage && (
              <div className="height_set py-3 w-100">
                <div className="d-flex flex-column gap-1">
                  <div className="Signup_Font d-flex justify-content-between align-items-center">
                    <div>Sign Up</div>{" "}
                    <div
                      className="pointer pe-3"
                      onClick={() => {
                        setsignupBoolean({
                          ...signupBoolean,
                          signupPage: true,
                          createPage: false,
                        });
                        setIsEmailReadOnly(false);
                        if (signupBoolean.verifyNumber) {
                          setmobileRead(true);
                        }
                      }}
                    >
                      <BsArrowLeft />
                    </div>
                  </div>
                  <div className="KP_Font">Sarojini Spectrum</div>
                  <div className="head_size">Enter Your Details</div>
                </div>

                <div className="d-flex flex-column justify-content-between Signup_height">
                  <div className="d-flex flex-column mt-3 ">
                    <div className="d-flex flex-column gap-1 ">
                      <Input
                        label={"Mobile Number"}
                        placeholder="Enter the Number"
                        value={mobileNumber}
                        otpOnChange={Mobile}
                        isDisabled={signupBoolean.verifyNumber}
                        readOnly={mobileRead}
                        showverify={mobileRead}
                        className={!mobileRead ? "mb-3" : ""}
                      />
                    </div>

                    {isEmailReadOnly && (
                      <>
                        <div className="d-flex flex-column gap-1">
                          <Input
                            label="First Name"
                            type="text"
                            placeholder="Enter the first name"
                            name="firstName"
                            value={firstName}
                            className={"captial mb-3"}
                            otpOnChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            readOnly
                          />
                        </div>
                        <div className="d-flex flex-column gap-1">
                          <Input
                            label="Last Name"
                            type="Last Name"
                            placeholder="Enter the last name"
                            name="Last Name"
                            className={"captial mb-3"}
                            value={lastName}
                            readOnly
                            otpOnChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}

                    {!isEmailReadOnly && (
                      <>
                        <div className="d-flex flex-column gap-1">
                          <Input
                            label="First Name"
                            type="text"
                            placeholder="Enter the first name"
                            name="firstName"
                            value={firstName}
                            className={"captial mb-3"}
                            otpOnChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            readOnly={!otpverifyForName}
                          />
                        </div>
                        <div className="d-flex flex-column gap-1">
                          <Input
                            label="Last Name"
                            type="Last Name"
                            placeholder="Enter the last name"
                            name="Last Name"
                            className={"captial mb-3"}
                            value={lastName}
                            readOnly={!firstName}
                            otpOnChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="d-flex flex-column gap-1">
                          <Input
                            label="Email ID"
                            type="email"
                            placeholder="Enter the email id"
                            name="email"
                            value={email}
                            className={"mb-3"}
                            otpOnChange={handleEmailChange}
                            readOnly={!lastName}
                            errorMessage={emailError}
                          />
                        </div>

                        <div className="d-flex flex-column gap-1">
                          <Select
                            label={"Gender"}
                            placeholder="Select Gender"
                            isDropdown
                            options={gender}
                            value={signupGender}
                            className={"captial mb-3"}
                            onChange={(e) => {
                              setSignupGender(e);
                            }}
                            isDisabled={!email}
                          />
                        </div>

                        <div className="d-flex flex-column gap-1">
                          <Input1
                            label={"Date of Birth"}
                            placeholder="Select Date of Birth"
                            type="date"
                            value={dateOfBirth}
                            readOnly={!signupGender} // Example: set to false to allow input
                            textFunc={setDateOfBirth}
                            maxDate={formattedMaxDate} // Ensure only past dates can be selected
                          // required={true}
                          />
                        </div>

                        {isPincodeReadOnly ? (
                          <div className="d-flex gap-1">
                            <div className="col">
                              <Input
                                label="Postal Pincode"
                                type="Postal Pincode"
                                name="Postal Pincode"
                                placeholder="Enter postal pincode"
                                value={pinCode}
                                readOnly={!dateOfBirth}
                                otpOnChange={PincodeModal}
                                isDisabled={dateOfBirth ? false : true}
                              />
                            </div>
                            <div className="col">
                              <Select
                                label={"Area"}
                                placeholder="Select Area"
                                isDropdown
                                options={areas.map((area) => ({
                                  value: area,
                                  label: area,
                                }))}
                                value={areasData}
                                onChange={(e) => {
                                  setAreasData(e);
                                }}
                                isDisabled={!email}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex flex-column gap-1">
                            <Input
                              label="Postal Pincode"
                              type="Postal Pincode"
                              name="Postal Pincode"
                              placeholder="Enter postal pincode"
                              value={pinCode}
                              readOnly={!dateOfBirth}
                              otpOnChange={PincodeModal}
                              isDisabled={dateOfBirth ? false : true}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {isEmailReadOnly ? (
                  <div className="pt-3">
                    <Button
                      onClick={() => {
                        setsignupBoolean({
                          ...signupBoolean,
                          signupPage: true,
                          createPage: false,
                        });
                        setIsEmailReadOnly(true);
                        setmobileRead(false);
                      }}
                      className="Button_Font Button_Style w-100"
                      backgroundcolor={"#B24BD6"}
                      color={"#fffff"}
                      label={"SignIn with Kpost ID"}
                    />
                  </div>
                ) : (
                  <div className="pt-3">
                    <Button
                      onClick={() => {
                        setsignupBoolean({
                          ...signupBoolean,
                          signupPage: false,
                          createPage: false,
                          // newpage: true,
                          confirmpassword: true,
                        });
                        setmobileRead(false);
                      }}
                      className="Button_Font Button_Style w-100"
                      disabled={areasData ? false : true}
                      backgroundcolor={areasData ? "#B24BD6" : "#E9D3F3"}
                      color={"#fffff"}
                      label={"Continue"}
                    />
                  </div>
                )}
                <div className="d-flex flex-column ">
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    Already Have an Account? &nbsp;{" "}
                    <span
                      onClick={() => {
                        setModalShow(true);
                        setsignupmodal(false);
                      }}
                      className="need_size"
                    >
                      {" "}
                      SignIn
                    </span>
                  </div>
                </div>
              </div>
            )}

            {signupBoolean.confirmpassword && (
              <>
                <div className="height_set py-3">
                  <div className="d-flex flex-column gap-1">
                    <div className="Signup_Font d-flex justify-content-between align-items-center">
                      <div>Sign Up</div>
                      <div
                        className="pointer pe-3"
                        onClick={() => {
                          setsignupBoolean({
                            signupPage: false,
                            createPage: true,
                            // newpage: true,
                            confirmpassword: false,
                          });
                        }}
                      >
                        <BsArrowLeft />
                      </div>
                    </div>
                    <div className="KP_Font">Sarojini Spectrum</div>
                    <div className="head_size">Enter Your Details</div>
                  </div>

                  <div
                    className="d-flex flex-column justify-content-between "
                    style={{ height: "40vh" }}
                  >
                    <div className="d-flex flex-column mt-3">
                      <Input
                        label="Preferred User ID"
                        required={"*"}
                        type="text"
                        placeholder="Enter Preferred User ID"
                        name="Preferred User ID"
                        value={kpostid}
                        otpOnChange={(e) => {
                          setKpostid(e.target.value);
                        }}
                      />
                      <div
                        className="d-flex justify-content-end agree_font pointer"
                        style={{ color: "#EA596E" }}
                        onClick={() => {
                          setsignupBoolean({
                            ...signupBoolean,
                            modalSuggestion: true,
                          });
                          handleGetSuggestions();
                        }}
                      >
                        Get Suggestion from KPOST
                      </div>
                      <Input
                        label="Password"
                        required={"*"}
                        type="password"
                        placeholder="Enter Password"
                        name="Password"
                        value={password}
                        readOnly={!kpostid}
                        className={"mb-3"}
                        otpOnChange={(e) => {
                          setPassword(e.target.value);
                          setCnfrmPassword("");
                        }}
                        showicon={true}
                        iconStyle={{
                          position: "relative",
                          bottom: "48px",
                          left: "91%",
                        }}
                      />
                      <Input
                        label="Confirm Password"
                        required={"*"}
                        type="password"
                        placeholder="Enter Confirm Password"
                        name="Confirm Password"
                        value={cnfrmpassword}
                        readOnly={!password}
                        otpOnChange={handleConfirmPasswordChange}
                        showicon={true}
                        iconStyle={{
                          position: "relative",
                          bottom: "33px",
                          left: "91%",
                        }}
                      />
                      {!passwordMatch && (
                        <p className="error-message">Passwords do not match!</p>
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row gap-2 align-items-center mb-2">
                    <div>
                      <Form.Check aria-label="option 1" />
                    </div>
                    <div className="agree_font">
                      I Agree With Data Policy,{" "}
                      <span style={{ color: "#B24BD6" }}>
                        Privacy Policy, Terms and Conditions
                      </span>
                    </div>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    SignUpHandle();
                  }}
                  className="Button_Font Button_Style "
                  disabled={passwordMatch ? false : true}
                  backgroundcolor={passwordMatch ? "#B24BD6" : "#E9D3F3"}
                  color={"#fffff"}
                  label={"Continue"}
                />

                <div className="d-flex flex-column pb-5">
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    Already Have an Account? &nbsp;{" "}
                    <span
                      onClick={() => {
                        setModalShow(true);
                        setsignupmodal(false);
                      }}
                      className="need_size"
                    >
                      {" "}
                      SignIn
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        }
      />

      <Modal
        dialogClassName="modal-50w"
        show={signupBoolean.modalSuggestion}
        centered
      >
        <div className="d-flex flex-column">
          <div>
            <Modal.Header className="d-flex justify-content-between align-items-center">
              <Modal.Title>Suggestion from KPOST</Modal.Title>
              <AiOutlineClose
                className="Icon_Style pointer"
                onClick={() => {
                  handleClose();
                }}
              />
            </Modal.Header>
          </div>
          <div className="p-3 ModalMain_Style">
            <div className="Modal_inside d-flex flex-column gap-2">
              <div className="d-flex flex-column gap-2">
                <div>
                  <label className="Label_Font" style={{ fontWeight: "600" }}>
                    Suggestion Lists
                  </label>
                </div>
                <div>
                  {suggestList.map((suggestion, index) => (
                    <div
                      key={index}
                      className="Borber-Bottom font-weight-bolder pointer suggestionList"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}@kpostindia.com
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex gap-4 mt-2">
                <div className="w-100">
                  <Input
                    label={"Enter New KPOST ID"}
                    className="w-100"
                    placeholder="Enter KPOST ID"
                    value={kpostid}
                    otpOnChange={(e) => {
                      setKpostid(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  handleClose();
                  // setVerifyEmail(true);
                }}
                className="Button_Style mt-2"
              >
                <button
                  className="Button_Font Button_Style w-100"
                  style={{ background: "#B24BD6" }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal dialogClassName="modal-50w" show={modalShow.show} centered>
        {signupBoolean.modalPhoneNumber && (
          <div className="d-flex flex-column">
            <div>
              <Modal.Header className="d-flex justify-content-between align-items-center">
                <Modal.Title>Enter Your OTP</Modal.Title>
                <AiOutlineClose
                  className="Icon_Style pointer"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Modal.Header>
            </div>

            <div className="p-3 ModalMain_Style">
              <div className="Modal_inside d-flex flex-column gap-2">
                <div className="Otp-font">
                  6 Digit OTP has been send to Your Registered Mobile Number
                  &nbsp;
                  <span className="NumberFont">+91 {mobileNumber}</span>
                </div>

                <div className="d-flex gap-3">
                  {otpNumber1.map((item, index) => (
                    <div key={index}>
                      <Input
                        style={{ width: "43px", border: "1px solid" }}
                        id={`input_${index}`}
                        className={"text-center"}
                        value={otpNumber1[index] || ""}
                        otpOnChange={(e) => handleChnageOtp(index, e)}
                        maxLength={"1"}
                        placeholder={"0"}
                        inputRef={(el) => (inputRefs.current[index] = el)} // Assign ref to each input
                      />
                    </div>
                  ))}
                </div>

                <div>
                  {timer !== null && (
                    <div className="d-flex justify-content-end Otp_Font">
                      {resend && (
                        <span
                          className={`cursor-pointer Color pointer ${disableResend ? "disabled" : ""
                            }`}
                          onClick={
                            disableResend
                              ? null
                              : () => {
                                sendOTP(mobileNumber);
                                setresend(false);
                              }
                          }
                          style={{
                            pointerEvents: disableResend ? "none" : "auto",
                            color: disableResend
                              ? "rgb(100 139 185)"
                              : "#054694",
                          }}
                        >
                          Resend OTP {timer > 0 && `(${timer}s)`}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <Button
                    onClick={() => {
                      handleOtpSubmit();
                    }}
                    className="Button_Font Button_Style mt-2 w-100"
                    disabled={
                      otpNumber1.filter((x) => x !== "").length === 6
                        ? false
                        : true
                    }
                    backgroundcolor={
                      otpNumber1.filter((x) => x !== "").length === 6
                        ? "#B24BD6"
                        : "#E9D3F3"
                    }
                    color={"#fffff"}
                    label={"Submit"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {signupBoolean.modalKpost && (
                    <div className="d-flex flex-column">
                        <div>
                            <Modal.Header className="d-flex justify-content-between align-items-center">
                                <Modal.Title>KPost</Modal.Title>
                                <AiOutlineClose
                                    className="Icon_Style pointer"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                />
                            </Modal.Header>
                        </div>
                        <div className="p-3 Modal_Style">
                            <div className="Modal_Div d-flex flex-column gap-2">
                                <div className="d-flex flex-column B_Bot Con_Font">
                                    Congratulations! You have Successfully completed the
                                    Signup.
                                </div>

                                <div className="d-flex flex-column gap-2">
                                    <div className="f-14">
                                        <b>Note:</b>
                                        <span>
                                            {" "}
                                            You can Login Using any of the Following for KPOST ID{" "}
                                        </span>
                                    </div>
                                    <div className="">
                                        <ul className="d-flex flex-column gap-2 px-4">
                                            <li>
                                                <div className="Li_Div_Font">
                                                    +91 {mobileNumber}
                                                </div>
                                                <div className="Li_Font">
                                                    (Registered Mobile No)
                                                </div>
                                            </li>
                                            <li>
                                                <div className="Li_Div_Font">{kpostid}</div>
                                                <div className="Li_Font">(Registered KPOST ID)</div>
                                            </li>
                                            <li>
                                                <div className="Li_Div_Font">{kpostid}</div>
                                                <div className="Li_Font">
                                                    (Registered KPOST ID@KPOST Domain)
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div
                                    onClick={() => {
                                        handleClose();
                                        // setVerifyEmail(true);
                                        navigate("/login");
                                    }}
                                    className="Button_Style mt-2"
                                >
                                    <button className="Button_Font">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
      </Modal>
    </>
  );
};

export default Header1;
