import React, { useState } from 'react'
import Home from './Home'
import Header1 from '../Header/Header1'
import { GetAllCountry } from '../Services/Signup';

const MainHomepage = () => {
  const [modalshow, setModalShow] = useState(false);
  const [countryAll, setcountryAll] = useState([]);

  const CountryGetAll = async () => {
    let response = await GetAllCountry();
    if (response.status === "SUCCESS") {
      if (response.data.length > 0) {
        let dataOPtion = response.data.map((e) => {
          return {
            ...e,
            label: `+${e.countryCode}     ${e.countryName}`,
            value: `${e.countryID}`,
          };
        });
        setcountryAll(dataOPtion);
      }
    }
  };


  return (
    <div>
      <Header1 modalshow={modalshow} setModalShow={setModalShow} countryAll={countryAll} setcountryAll={setcountryAll} CountryGetAll={CountryGetAll} />
      <Home loginCall={() => {setModalShow(true); CountryGetAll()}} />
    </div>
  )
}

export default MainHomepage
